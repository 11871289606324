/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ItemModel } from '../models/item-model';

/**
 * Search Controller
 */
@Injectable({
  providedIn: 'root',
})
class SearchControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `SearchControllerService.SearchContentsUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  searchContentsUsingGETResponse(params: SearchControllerService.SearchContentsUsingGETParams): __Observable<__StrictHttpResponse<Array<ItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/search/contents`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ItemModel>>;
      })
    );
  }
  /**
   * @param params The `SearchControllerService.SearchContentsUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  searchContentsUsingGET(params: SearchControllerService.SearchContentsUsingGETParams): __Observable<Array<ItemModel>> {
    return this.searchContentsUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ItemModel>)
    );
  }

  /**
   * @param params The `SearchControllerService.SearchPageTemplatesUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  searchPageTemplatesUsingGETResponse(params: SearchControllerService.SearchPageTemplatesUsingGETParams): __Observable<__StrictHttpResponse<Array<ItemModel>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/search/pagetemplates`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ItemModel>>;
      })
    );
  }
  /**
   * @param params The `SearchControllerService.SearchPageTemplatesUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  searchPageTemplatesUsingGET(params: SearchControllerService.SearchPageTemplatesUsingGETParams): __Observable<Array<ItemModel>> {
    return this.searchPageTemplatesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ItemModel>)
    );
  }
}

module SearchControllerService {

  /**
   * Parameters for searchContentsUsingGET
   */
  export interface SearchContentsUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * q
     */
    q?: string;
  }

  /**
   * Parameters for searchPageTemplatesUsingGET
   */
  export interface SearchPageTemplatesUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * q
     */
    q?: string;
  }
}

export { SearchControllerService }
