import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Collection Type Controller
 */
class CollectionTypeControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    findAllCollectionTypeUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    findAllCollectionTypeUsingGET() {
        return this.findAllCollectionTypeUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param collectionType collectionType
     * @return OK
     */
    addCollectionTypeUsingPOSTResponse(collectionType) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = collectionType;
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param collectionType collectionType
     * @return OK
     */
    addCollectionTypeUsingPOST(collectionType) {
        return this.addCollectionTypeUsingPOSTResponse(collectionType).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    findOneCollectionTypeUsingGETResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    findOneCollectionTypeUsingGET(id) {
        return this.findOneCollectionTypeUsingGETResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionType`: collectionType
     *
     * @return OK
     */
    updateCollectionTypeUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.collectionType;
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionType`: collectionType
     *
     * @return OK
     */
    updateCollectionTypeUsingPOST(params) {
        return this.updateCollectionTypeUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     */
    updateCollectionTypeUsingDELETEResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/webitem/collection/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     */
    updateCollectionTypeUsingDELETE(id) {
        return this.updateCollectionTypeUsingDELETEResponse(id).pipe(__map(_r => _r.body));
    }
}
CollectionTypeControllerService.ngInjectableDef = i0.defineInjectable({ factory: function CollectionTypeControllerService_Factory() { return new CollectionTypeControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CollectionTypeControllerService, providedIn: "root" });
export { CollectionTypeControllerService };
