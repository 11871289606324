/* tslint:disable */
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration } from './api-configuration';

import { RendererRestControllerService } from './services/renderer-rest-controller.service';
import { AccountRestControllerService } from './services/account-rest-controller.service';
import { AdminSiteRestControllerService } from './services/admin-site-rest-controller.service';
import { ContentTypeControllerService } from './services/content-type-controller.service';
import { DeployRestControllerService } from './services/deploy-rest-controller.service';
import { InternalItemModelControllerService } from './services/internal-item-model-controller.service';
import { ItemRestControllerService } from './services/item-rest-controller.service';
import { ItemsListControllerService } from './services/items-list-controller.service';
import { SearchControllerService } from './services/search-controller.service';
import { SiteRestControllerService } from './services/site-rest-controller.service';
import { SyncControllerService } from './services/sync-controller.service';
import { CollectionTypeControllerService } from './services/collection-type-controller.service';
import { WebContentRestControllerService } from './services/web-content-rest-controller.service';
import { WebResourceRestControllerService } from './services/web-resource-rest-controller.service';
import { WebPageRestControllerService } from './services/web-page-rest-controller.service';
import { CollectionControllerService } from './services/collection-controller.service';
import { RevisionTagControllerService } from './services/revision-tag-controller.service';
import { BasicErrorControllerService } from './services/basic-error-controller.service';
import { ProviderControllerService } from './services/provider-controller.service';
import { WebControllerService } from './services/web-controller.service';
import { UserRestControllerService } from './services/user-rest-controller.service';
import { ColaborationControllerService } from './services/colaboration-controller.service';
import { UserControllerService } from './services/user-controller.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    RendererRestControllerService,
    AccountRestControllerService,
    AdminSiteRestControllerService,
    ContentTypeControllerService,
    DeployRestControllerService,
    InternalItemModelControllerService,
    ItemRestControllerService,
    ItemsListControllerService,
    SearchControllerService,
    SiteRestControllerService,
    SyncControllerService,
    CollectionTypeControllerService,
    WebContentRestControllerService,
    WebResourceRestControllerService,
    WebPageRestControllerService,
    CollectionControllerService,
    RevisionTagControllerService,
    BasicErrorControllerService,
    ProviderControllerService,
    WebControllerService,
    UserRestControllerService,
    ColaborationControllerService,
    UserControllerService
  ],
})
export class ApiModule { }
