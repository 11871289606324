/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/primeng/components/button/button.ngfactory";
import * as i2 from "primeng/components/button/button";
import * as i3 from "./profile.component";
var styles_ProfileComponent = [];
var RenderType_ProfileComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileComponent, data: {} });
export { RenderType_ProfileComponent as RenderType_ProfileComponent };
export function View_ProfileComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "header-profile"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "header-profile__name"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "p-button", [["icon", "sh sh-log-out"], ["styleClass", "ui-button-transparent ui-button-transparent--large"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onLogout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Button_0, i1.RenderType_Button)), i0.ɵdid(4, 49152, null, 0, i2.Button, [], { icon: [0, "icon"], styleClass: [1, "styleClass"] }, { onClick: "onClick" })], function (_ck, _v) { var currVal_1 = "sh sh-log-out"; var currVal_2 = "ui-button-transparent ui-button-transparent--large"; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.account.name; _ck(_v, 2, 0, currVal_0); }); }
export function View_ProfileComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-profile", [], null, null, null, View_ProfileComponent_0, RenderType_ProfileComponent)), i0.ɵdid(1, 114688, null, 0, i3.ProfileComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileComponentNgFactory = i0.ɵccf("app-profile", i3.ProfileComponent, View_ProfileComponent_Host_0, { account: "account" }, { logout: "logout" }, []);
export { ProfileComponentNgFactory as ProfileComponentNgFactory };
