import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Account } from '@api/models';

@Component({
  selector: 'app-profile',
  template: `
    <div class="header-profile">
      <span class="header-profile__name">{{account.name}}</span>
      <p-button 
        icon="sh sh-log-out" 
        styleClass="ui-button-transparent ui-button-transparent--large"
        (onClick)="onLogout()"
      ></p-button>
    </div>

  `
})
export class ProfileComponent implements OnInit {
  @Input() account: Account;
  @Output() logout: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onLogout() {
    this.logout.next();
  }
}
