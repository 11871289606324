import { Component } from '@angular/core';
import { AuthService } from '../../../core/auth.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { LoginModel } from '../state/auth.state';
import { Store } from '@ngxs/store';
import { Login } from '../state/auth.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  isError = false;

  loginForm = this.formBuilder.formGroup(new LoginModel());

  constructor(
    private readonly router: Router,
    private auth: AuthService,
    private formBuilder: RxFormBuilder,
    private store: Store
  ) {
    this.auth.logout();
  }

  async submitForm() {
    this.isError = false;

    try {
      await this.store.dispatch(new Login()).toPromise();
    } catch (error) {
      this.isError = true;
    }
  }

  startResetPassword() {
    this.router.navigate(['auth/send-password-token']);
  }
}
