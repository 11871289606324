/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./private.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/primeng/components/tabmenu/tabmenu.ngfactory";
import * as i3 from "primeng/components/tabmenu/tabmenu";
import * as i4 from "../profile/profile.component.ngfactory";
import * as i5 from "../profile/profile.component";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../libs/ui-library/src/lib/components/logo/logo.component.ngfactory";
import * as i8 from "../../../../../../libs/ui-library/src/lib/components/logo/logo.component";
import * as i9 from "@angular/router";
import * as i10 from "./private.component";
import * as i11 from "@ngxs/store";
var styles_PrivateComponent = [i0.styles];
var RenderType_PrivateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivateComponent, data: {} });
export { RenderType_PrivateComponent as RenderType_PrivateComponent };
export function View_PrivateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "header", [["class", "main-header p-grid p-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "p-col p-col-align-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "p-tabMenu", [], null, null, null, i2.View_TabMenu_0, i2.RenderType_TabMenu)), i1.ɵdid(4, 1097728, null, 1, i3.TabMenu, [], { model: [0, "model"] }, null), i1.ɵqud(603979776, 1, { templates: 1 }), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "p-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-profile", [], null, [[null, "logout"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("logout" === en)) {
        var pd_0 = (_co.logout() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ProfileComponent_0, i4.RenderType_ProfileComponent)), i1.ɵdid(8, 114688, null, 0, i5.ProfileComponent, [], { account: [0, "account"] }, { logout: "logout" }), i1.ɵpid(131072, i6.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 2, "aside", [["class", "left-side"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "sh-logo", [], null, null, null, i7.View_LogoComponent_0, i7.RenderType_LogoComponent)), i1.ɵdid(12, 114688, null, 0, i8.LogoComponent, [], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 3, "section", [["class", "main-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "main-content__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(16, 212992, null, 0, i9.RouterOutlet, [i9.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menu; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.selectedAccount$)); _ck(_v, 8, 0, currVal_1); _ck(_v, 12, 0); _ck(_v, 16, 0); }, null); }
export function View_PrivateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-private", [], null, null, null, View_PrivateComponent_0, RenderType_PrivateComponent)), i1.ɵdid(1, 49152, null, 0, i10.PrivateComponent, [i11.Store], null, null)], null, null); }
var PrivateComponentNgFactory = i1.ɵccf("app-private", i10.PrivateComponent, View_PrivateComponent_Host_0, {}, {}, []);
export { PrivateComponentNgFactory as PrivateComponentNgFactory };
