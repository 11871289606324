import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { AuthStateModel } from '../public/authentication/state/auth.state';
import { isAfter } from 'date-fns';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url) {
    const { token, expirationDate } = this.store.selectSnapshot<AuthStateModel>(state => state.auth);
    if (token && !isAfter(new Date(), new Date(expirationDate))) {
      return true;
    }
    this.store.dispatch(new Navigate(['auth']));
    return false;
  }
}
