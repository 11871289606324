import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sh-logo',
  template: '<h1 class="sh sh-logo-simple"></h1>',
  styles: [
    `
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h1 {
      margin: 0;
      font-size: 25px;
      line-height: 3rem;
    }
  `
  ]
})
export class LogoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
