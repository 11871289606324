
    <div class="container login-container">
      <section>
        <p-card>
          <p-header *ngIf="isTabsVisible"> <p-tabMenu [model]="items"></p-tabMenu> </p-header>

          <router-outlet></router-outlet>
        </p-card>
      </section>
      <aside><img src="/assets/gif_kadr.gif" alt="" /></aside>
    </div>
  