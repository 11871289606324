import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Revision Tag Controller
 */
class RevisionTagControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    findAllCollectionTypeUsingGET1Response() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/revision`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    findAllCollectionTypeUsingGET1() {
        return this.findAllCollectionTypeUsingGET1Response().pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    addCollectionTypeUsingPOST1Response(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = revisionTag;
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/revision`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    addCollectionTypeUsingPOST1(revisionTag) {
        return this.addCollectionTypeUsingPOST1Response(revisionTag).pipe(__map(_r => _r.body));
    }
}
RevisionTagControllerService.ngInjectableDef = i0.defineInjectable({ factory: function RevisionTagControllerService_Factory() { return new RevisionTagControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: RevisionTagControllerService, providedIn: "root" });
export { RevisionTagControllerService };
