<div class="p-grid">
  <div class="p-col-12"><h3>My sites</h3></div>
  <div class="p-col">
    <sh-button-with-label
      icon="sh sh-plus-add"
      styleClass="ui-button-round ui-button--shadow"
      label="Add site"
      (onClick)="openModal()"
    ></sh-button-with-label>
  </div>
</div>
<br />
<div class="p-grid">
  <div *ngFor="let site of sitesList" class="p-col">
    <p-panel class="site-panel" [toggleable]="true" [collapsed]="false">
      <p-header>
        <span class="sh sh-page ui-panel-titlebar__icon"></span>
        <span class="ui-panel-titlebar__text">{{ site.name }}</span>
      </p-header>

      <p-panel *ngFor="let env of site.environments" [toggleable]="true" [collapsed]="true">
        <p-header>
          <div class="p-grid p-nogutter p-align-center">
            <div class="p-col">
              Enviroment:
              <p-message severity="info" [text]="env.type"></p-message>
            </div>

            <div class="p-col">
              <a class="link-decorated" [href]="env.domain" target="_blank">{{ env.domain }}</a>
              <p-button
                styleClass="ui-button-transparent"
                icon="fa fa-fw fa-cog"
                (onClick)="onConfig(env.domain)"
              ></p-button>
            </div>

            <div class="p-col">
              <p-button
                icon="sh sh-trash"
                styleClass="ui-button-transparent ui-button--black"
                (onClick)="deleteSite(site)"
              ></p-button>
            </div>
          </div>
        </p-header>
        <p>To start work on site run in terminal:</p>
        <pre>yo @smarthtml/site --siteUrl="{{ site.environments[0].domain }}"  --username="{{ user.principal }}"</pre>
        <a
          class="link-decorated"
          target="_blank"
          href="https://smarthtml.io/tutorial/generate-development-environment.html"
          >See more detailed documentation</a
        >
      </p-panel>
    </p-panel>
  </div>
</div>

<div *ngIf="sharedSitesList.length > 0" class="p-grid">
  <div class="p-col-12"><h3>Shared</h3></div>
  <div *ngFor="let site of sharedSitesList" class="p-col">
    <p-panel class="site-panel" [toggleable]="true" [collapsed]="false">
      <p-header>
        <span class="sh sh-page ui-panel-titlebar__icon"></span>
        <span class="ui-panel-titlebar__text">{{ site.name }}</span>
      </p-header>

      <p-panel *ngFor="let env of site.environments" [toggleable]="true" [collapsed]="true">
        <p-header>
          <div class="p-grid p-nogutter p-align-center">
            <div class="p-col">
              Enviroment:
              <p-message severity="info" [text]="env.type"></p-message>
            </div>

            <div class="p-col">
              <a class="link-decorated" [href]="env.domain" target="_blank">{{ env.domain }}</a>
              <p-button
                styleClass="ui-button-transparent"
                icon="fa fa-fw fa-cog"
                (onClick)="onConfig(env.domain)"
              ></p-button>
            </div>

            <div class="p-col">
              <p-button
                icon="sh sh-trash"
                styleClass="ui-button-transparent ui-button--black"
                (onClick)="leaveSite(site)"
              ></p-button>
            </div>
          </div>
        </p-header>
        <p>To start work on site run in terminal:</p>
        <pre>yo @smarthtml/site --siteUrl="{{ site.environments[0].domain }}"  --username="{{ user.principal }}"</pre>
        <a
          class="link-decorated"
          target="_blank"
          href="https://smarthtml.io/tutorial/generate-development-environment.html"
          >See more detailed documentation</a
        >
      </p-panel>
    </p-panel>
  </div>
</div>

<p-dialog modal="modal" [(visible)]="isDialogVisible" [responsive]="true" [width]="350" [minWidth]="200" [minY]="70">
  <p-header>
    <div class="ui-dialog-title-inner">
      <span class="ui-dialog-title-inner__icon sh sh-page"></span>
      <span class="ui-dialog-title-inner__text">Add new site</span>
    </div>
  </p-header>

  <div class="p-grid">
    <div class=" p-col">
      <input
        pInputText
        type="text"
        name="siteName"
        id="siteName"
        [(ngModel)]="newSiteModel.site.name"
        required
        placeholder="Site name"
      />
    </div>
  </div>

  <p-footer>
    <div class="p-grid">
      <div class="p-col"><button type="button" pButton (click)="closeModal()" label="Cancel"></button></div>
      <div class="p-col"><button type="button" pButton (click)="addNewSite()" label="Add"></button></div>
    </div>
  </p-footer>
</p-dialog>

<p-confirmDialog #confirm>
  <p-footer>
    <div class="p-grid">
      <div class="p-col"><button type="button" pButton (click)="confirm.reject()" label="Cancel"></button></div>
      <div class="p-col"><button type="button" pButton (click)="confirm.accept()" label="Delete"></button></div>
    </div>
  </p-footer>
</p-confirmDialog>
