import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Web Content Rest Controller
 */
class WebContentRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `WebContentRestControllerService.FindOneUsingGET1Params` containing the following parameters:
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    findOneUsingGET1Response(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `WebContentRestControllerService.FindOneUsingGET1Params` containing the following parameters:
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    findOneUsingGET1(params) {
        return this.findOneUsingGET1Response(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `WebContentRestControllerService.SaveUsingPOSTParams` containing the following parameters:
     *
     * - `item`: item
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    saveUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.item;
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `WebContentRestControllerService.SaveUsingPOSTParams` containing the following parameters:
     *
     * - `item`: item
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    saveUsingPOST(params) {
        return this.saveUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `WebContentRestControllerService.GetResourcesUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    getResourcesUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content/all`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `WebContentRestControllerService.GetResourcesUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    getResourcesUsingGET(params) {
        return this.getResourcesUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `WebContentRestControllerService.SavePreferencesUsingPOSTParams` containing the following parameters:
     *
     * - `item`: item
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    savePreferencesUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.item;
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content/preferences`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `WebContentRestControllerService.SavePreferencesUsingPOSTParams` containing the following parameters:
     *
     * - `item`: item
     *
     * - `filePath`: filePath
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    savePreferencesUsingPOST(params) {
        return this.savePreferencesUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
}
WebContentRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function WebContentRestControllerService_Factory() { return new WebContentRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: WebContentRestControllerService, providedIn: "root" });
export { WebContentRestControllerService };
