export interface IFormModel<T> {
  model: T | null;
  dirty: boolean;
  status: string;
  errors: any;
}

export const getFormModelDefaults = (model = null) => ({
  model: model,
  dirty: false,
  status: '',
  errors: null
});
