import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { PublicComponent } from './public/public.component';
import { UiLibraryModule } from '@smart-html/ui-library';
import { AuthenticationModule } from './authentication/authentication.module';

@NgModule({
  imports: [CommonModule, PublicRoutingModule, UiLibraryModule, AuthenticationModule],
  declarations: [PublicComponent]
})
export class PublicModule {}
