import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivateComponent } from './private/private.component';
import { SitesComponent } from './sites/sites.component';
import { AuthGuard } from '../core/auth.guard';
import { TestComponent } from './test/test.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {
    path: 'private',
    canActivate: [AuthGuard],
    component: PrivateComponent,
    children: [
      {
        path: '',
        redirectTo: 'sites',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'sites',
        component: SitesComponent
      },
      {
        path: 'test',
        component: TestComponent
      },
      {
        path: 'invitations',
        loadChildren: 'apps/client-panel/src/app/private/invitations/invitations.module#InvitationsModule'
      },
      {
        path: 'profile',
        component: ProfileComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PrivateRoutingModule {}
