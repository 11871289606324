import * as tslib_1 from "tslib";
import { Action, State } from '@ngxs/store';
import { getFormModelDefaults } from '@smart-html-panel/types/types';
import { prop, required } from '@rxweb/reactive-form-validators';
import { Login, Logout, ResetPasswordAction, SendPasswordLinkAction } from './auth.actions';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Navigate } from '@ngxs/router-plugin';
import { UserRestControllerService } from '@api/services/user-rest-controller.service';
export class LoginModel {
    constructor() {
        this.username = '';
        this.password = '';
    }
}
tslib_1.__decorate([
    required(),
    prop(),
    tslib_1.__metadata("design:type", Object)
], LoginModel.prototype, "username", void 0);
tslib_1.__decorate([
    required(),
    prop(),
    tslib_1.__metadata("design:type", Object)
], LoginModel.prototype, "password", void 0);
export class AuthStateModel {
}
let AuthState = class AuthState {
    constructor(http, userControllerService) {
        this.http = http;
        this.userControllerService = userControllerService;
    }
    login({ patchState, getState, dispatch }) {
        const loginData = getState().loginForm.model;
        const formData = new FormData();
        Object.keys(loginData).forEach(key => {
            formData.append(key, loginData[key]);
        });
        return this.http.post(`/login`, formData).pipe(tap(({ token, expirationDate }) => {
            patchState({
                token,
                expirationDate
            });
            dispatch(new Navigate(['private']));
        }));
    }
    sendPasswordLink({ patchState, getState, dispatch }, { payload }) {
        return this.userControllerService
            .sendPasswordTokenUsingPOST(payload)
            .pipe(tap(() => {
            dispatch(new Navigate(['auth/send-password-token-success']));
        }));
    }
    resetPassword({ patchState, getState, dispatch }, { payload }) {
        return this.userControllerService
            .resetPasswordUsingPOST(payload)
            .pipe(tap(() => {
            dispatch(new Navigate(['auth/reset-password-success']));
        }));
    }
    logout({ patchState, dispatch }) {
        patchState({
            token: null,
            expirationDate: null
        });
        dispatch(new Navigate(['auth']));
    }
};
tslib_1.__decorate([
    Action(Login),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AuthState.prototype, "login", null);
tslib_1.__decorate([
    Action(SendPasswordLinkAction),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SendPasswordLinkAction]),
    tslib_1.__metadata("design:returntype", void 0)
], AuthState.prototype, "sendPasswordLink", null);
tslib_1.__decorate([
    Action(ResetPasswordAction),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, ResetPasswordAction]),
    tslib_1.__metadata("design:returntype", void 0)
], AuthState.prototype, "resetPassword", null);
tslib_1.__decorate([
    Action(Logout),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AuthState.prototype, "logout", null);
AuthState = tslib_1.__decorate([
    State({
        name: 'auth',
        defaults: {
            token: null,
            expirationDate: null,
            loginForm: getFormModelDefaults()
        }
    }),
    tslib_1.__metadata("design:paramtypes", [HttpClient, UserRestControllerService])
], AuthState);
export { AuthState };
