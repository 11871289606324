/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseEntity } from '../models/response-entity';
import { RenderItem } from '../models/render-item';
import { RenderedItem } from '../models/rendered-item';

/**
 * Renderer Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class RendererRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `RendererRestControllerService.ProcessUsingGETParams` containing the following parameters:
   *
   * - `personaName`: personaName
   *
   * - `persona`: persona
   *
   * - `collectionId`: collectionId
   *
   * @return OK
   */
  processUsingGETResponse(params: RendererRestControllerService.ProcessUsingGETParams): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.personaName != null) __params = __params.set('personaName', params.personaName.toString());
    if (params.persona != null) __params = __params.set('persona', params.persona.toString());
    if (params.collectionId != null) __params = __params.set('collectionId', params.collectionId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * @param params The `RendererRestControllerService.ProcessUsingGETParams` containing the following parameters:
   *
   * - `personaName`: personaName
   *
   * - `persona`: persona
   *
   * - `collectionId`: collectionId
   *
   * @return OK
   */
  processUsingGET(params: RendererRestControllerService.ProcessUsingGETParams): __Observable<ResponseEntity> {
    return this.processUsingGETResponse(params).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }

  /**
   * @param params The `RendererRestControllerService.GetFragmentUsingGETParams` containing the following parameters:
   *
   * - `removeHostNode`: removeHostNode
   *
   * - `fragmentSpec`: fragmentSpec
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getFragmentUsingGETResponse(params: RendererRestControllerService.GetFragmentUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.removeHostNode != null) __params = __params.set('removeHostNode', params.removeHostNode.toString());
    if (params.fragmentSpec != null) __params = __params.set('fragmentSpec', params.fragmentSpec.toString());
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/render/fragment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `RendererRestControllerService.GetFragmentUsingGETParams` containing the following parameters:
   *
   * - `removeHostNode`: removeHostNode
   *
   * - `fragmentSpec`: fragmentSpec
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getFragmentUsingGET(params: RendererRestControllerService.GetFragmentUsingGETParams): __Observable<string> {
    return this.getFragmentUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  refreshRenderItemsUsingPOSTResponse(revisionTag?: string): __Observable<__StrictHttpResponse<Array<RenderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (revisionTag != null) __params = __params.set('revisionTag', revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/render/refresh`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RenderItem>>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  refreshRenderItemsUsingPOST(revisionTag?: string): __Observable<Array<RenderItem>> {
    return this.refreshRenderItemsUsingPOSTResponse(revisionTag).pipe(
      __map(_r => _r.body as Array<RenderItem>)
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  getRenderItemsUsingGETResponse(revisionTag?: string): __Observable<__StrictHttpResponse<Array<RenderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (revisionTag != null) __params = __params.set('revisionTag', revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/render/renderitems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RenderItem>>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  getRenderItemsUsingGET(revisionTag?: string): __Observable<Array<RenderItem>> {
    return this.getRenderItemsUsingGETResponse(revisionTag).pipe(
      __map(_r => _r.body as Array<RenderItem>)
    );
  }

  /**
   * @param renderitemId renderitemId
   */
  deleteRenderItemUsingDELETEResponse(renderitemId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/render/renderitems/${renderitemId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param renderitemId renderitemId
   */
  deleteRenderItemUsingDELETE(renderitemId: number): __Observable<null> {
    return this.deleteRenderItemUsingDELETEResponse(renderitemId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `RendererRestControllerService.RenderItemUsingGETParams` containing the following parameters:
   *
   * - `resourcePath`: resourcePath
   *
   * - `revisionTag`: revisionTag
   *
   * - `personaName`: personaName
   *
   * - `persona`: persona
   *
   * @return OK
   */
  renderItemUsingGETResponse(params: RendererRestControllerService.RenderItemUsingGETParams): __Observable<__StrictHttpResponse<RenderedItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.resourcePath != null) __params = __params.set('resourcePath', params.resourcePath.toString());
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.personaName != null) __params = __params.set('personaName', params.personaName.toString());
    if (params.persona != null) __params = __params.set('persona', params.persona.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/render/resource`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RenderedItem>;
      })
    );
  }
  /**
   * @param params The `RendererRestControllerService.RenderItemUsingGETParams` containing the following parameters:
   *
   * - `resourcePath`: resourcePath
   *
   * - `revisionTag`: revisionTag
   *
   * - `personaName`: personaName
   *
   * - `persona`: persona
   *
   * @return OK
   */
  renderItemUsingGET(params: RendererRestControllerService.RenderItemUsingGETParams): __Observable<RenderedItem> {
    return this.renderItemUsingGETResponse(params).pipe(
      __map(_r => _r.body as RenderedItem)
    );
  }
}

module RendererRestControllerService {

  /**
   * Parameters for processUsingGET
   */
  export interface ProcessUsingGETParams {

    /**
     * personaName
     */
    personaName?: string;

    /**
     * persona
     */
    persona?: string;

    /**
     * collectionId
     */
    collectionId?: number;
  }

  /**
   * Parameters for getFragmentUsingGET
   */
  export interface GetFragmentUsingGETParams {

    /**
     * removeHostNode
     */
    removeHostNode: boolean;

    /**
     * fragmentSpec
     */
    fragmentSpec: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for renderItemUsingGET
   */
  export interface RenderItemUsingGETParams {

    /**
     * resourcePath
     */
    resourcePath: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * personaName
     */
    personaName?: string;

    /**
     * persona
     */
    persona?: string;
  }
}

export { RendererRestControllerService }
