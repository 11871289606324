/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { RevisionTag } from '../models/revision-tag';

/**
 * Revision Tag Controller
 */
@Injectable({
  providedIn: 'root',
})
class RevisionTagControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  findAllCollectionTypeUsingGET1Response(): __Observable<__StrictHttpResponse<Array<RevisionTag>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/revision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RevisionTag>>;
      })
    );
  }
  /**
   * @return OK
   */
  findAllCollectionTypeUsingGET1(): __Observable<Array<RevisionTag>> {
    return this.findAllCollectionTypeUsingGET1Response().pipe(
      __map(_r => _r.body as Array<RevisionTag>)
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  addCollectionTypeUsingPOST1Response(revisionTag: RevisionTag): __Observable<__StrictHttpResponse<RevisionTag>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = revisionTag;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/revision`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RevisionTag>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  addCollectionTypeUsingPOST1(revisionTag: RevisionTag): __Observable<RevisionTag> {
    return this.addCollectionTypeUsingPOST1Response(revisionTag).pipe(
      __map(_r => _r.body as RevisionTag)
    );
  }
}

module RevisionTagControllerService {
}

export { RevisionTagControllerService }
