import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserRestControllerService } from '@api/services';
import { SignupModel } from '@api/models/signup-model';

interface ILoginResponse {
  token: string;
}

@Injectable()
export class AuthService {
  redirectUrl = '';
  private host = 'https://app.smart-html.com';

  constructor(private http: HttpClient, private router: Router, private userRestService: UserRestControllerService) {}

  login({ username, password }): Promise<boolean> {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);

    return this.http
      .post<ILoginResponse>(`${this.host}/login`, formData)
      .toPromise()
      .then(({ token }) => {
        this.setSession(token);
        this.setUsername(username);

        if (this.redirectUrl) {
          this.router.navigate([this.redirectUrl]);
        } else {
          this.router.navigate(['private']);
        }

        return true;
      });
  }

  private setSession(token) {
    localStorage.setItem('id_token', token);
  }

  private setUsername(username) {
    localStorage.setItem('username', username);
  }

  logout() {
    localStorage.removeItem('id_token');
    this.router.navigate(['auth']);
  }

  isLoggedIn() {
    const token = localStorage.getItem('id_token');
    return !!token;
  }

  getUsername() {
    return localStorage.getItem('username');
  }
  signUp(signupData: SignupModel) {
    return this.userRestService.signUpUsingPOST(signupData).toPromise();
  }
}
