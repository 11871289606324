import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-authentication',
  template: `
    <div class="container login-container">
      <section>
        <p-card>
          <p-header *ngIf="isTabsVisible"> <p-tabMenu [model]="items"></p-tabMenu> </p-header>

          <router-outlet></router-outlet>
        </p-card>
      </section>
      <aside><img src="/assets/gif_kadr.gif" alt="" /></aside>
    </div>
  `,
  styles: []
})
export class AuthenticationComponent implements OnInit {
  items: MenuItem[] = [
    {
      label: 'Login',
      routerLink: 'login'
    },
    {
      label: 'Signup',
      routerLink: 'signup'
    }
  ];

  isTabsVisible = true;

  constructor(router: Router) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((value: NavigationEnd) => {
      this.isTabsVisible = value.urlAfterRedirects === '/auth/login' || value.urlAfterRedirects === '/auth/signup';
    });
  }

  ngOnInit() {}
}
