import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordAction } from '../state/auth.actions';
import { Store } from '@ngxs/store';
export class ResetPasswordComponent {
    constructor(route, router, store) {
        this.route = route;
        this.router = router;
        this.store = store;
        this.isError = false;
        this.token = '';
        this.model = {};
    }
    ngOnInit() {
        //this.token = this.route.snapshot.paramMap.get("token");
        this.route.paramMap.subscribe(params => {
            this.token = params.get('token');
            this.model.token = this.token;
        });
    }
    submitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isError = false;
            try {
                yield this.store.dispatch(new ResetPasswordAction(this.model)).toPromise();
            }
            catch (error) {
                this.isError = true;
            }
        });
    }
    backToLogin() {
        this.router.navigate(['auth/login']);
    }
}
