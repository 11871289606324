import { Component, Input, OnInit } from '@angular/core';

export interface IVersionData {
  dirty: boolean;
  raw: string;
  hash: string;
  distance: number;
  tag: string;
  semver: {
    raw: string;
    major: number;
    minor: number;
    patch: number;
    prerelease?: any;
    build: any[];
    version: string;
  };
  suffix: string;
  semverString: string;
  version: string;
}

@Component({
  selector: 'sh-app-version',
  template: `

    <div class="app-version">
      {{version}}
    </div>
  `
})
export class AppVersionComponent implements OnInit {
  @Input() private versionData: IVersionData;

  version: string;

  constructor() {}

  ngOnInit() {
    this.version = `v ${this.versionData.version}`;
  }
}
