import * as tslib_1 from "tslib";
import { AccountsState, LoadAccounts } from '../../store/accounts.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { Logout } from '../../public/authentication/state/auth.actions';
import { GetUserInfo } from '../../store/app.state';
export class PrivateComponent {
    constructor(store) {
        this.store = store;
        this.menu = [
            {
                label: 'Sites',
                routerLink: '/private/sites'
            },
            {
                label: 'Invitaions',
                routerLink: '/private/invitations'
            }
        ];
        this.store.dispatch(new LoadAccounts());
        this.store.dispatch(new GetUserInfo());
    }
    logout() {
        this.store.dispatch(new Logout());
    }
}
tslib_1.__decorate([
    Select(AccountsState.accounts),
    tslib_1.__metadata("design:type", Observable)
], PrivateComponent.prototype, "accounts$", void 0);
tslib_1.__decorate([
    Select(AccountsState.selectedAccount),
    tslib_1.__metadata("design:type", Observable)
], PrivateComponent.prototype, "selectedAccount$", void 0);
