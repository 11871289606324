/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ItemsList } from '../models/items-list';
import { ContentItem } from '../models/content-item';
import { ItemsListEntry } from '../models/items-list-entry';

/**
 * Items List Controller
 */
@Injectable({
  providedIn: 'root',
})
class ItemsListControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  findUsingGETResponse(): __Observable<__StrictHttpResponse<Array<ItemsList>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/itemslist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ItemsList>>;
      })
    );
  }
  /**
   * @return OK
   */
  findUsingGET(): __Observable<Array<ItemsList>> {
    return this.findUsingGETResponse().pipe(
      __map(_r => _r.body as Array<ItemsList>)
    );
  }

  /**
   * @param itemsList itemsList
   * @return OK
   */
  createUsingPOST1Response(itemsList: ItemsList): __Observable<__StrictHttpResponse<ItemsList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = itemsList;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/itemslist`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemsList>;
      })
    );
  }
  /**
   * @param itemsList itemsList
   * @return OK
   */
  createUsingPOST1(itemsList: ItemsList): __Observable<ItemsList> {
    return this.createUsingPOST1Response(itemsList).pipe(
      __map(_r => _r.body as ItemsList)
    );
  }

  /**
   * @param params The `ItemsListControllerService.FindListItemsUsingGETParams` containing the following parameters:
   *
   * - `listname`: listname
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findListItemsUsingGETResponse(params: ItemsListControllerService.FindListItemsUsingGETParams): __Observable<__StrictHttpResponse<Array<ContentItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/itemslist/${params.listname}/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ContentItem>>;
      })
    );
  }
  /**
   * @param params The `ItemsListControllerService.FindListItemsUsingGETParams` containing the following parameters:
   *
   * - `listname`: listname
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findListItemsUsingGET(params: ItemsListControllerService.FindListItemsUsingGETParams): __Observable<Array<ContentItem>> {
    return this.findListItemsUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<ContentItem>)
    );
  }

  /**
   * @param params The `ItemsListControllerService.AddItemsListEntryUsingPOSTParams` containing the following parameters:
   *
   * - `listname`: listname
   *
   * - `itemsListEntry`: itemsListEntry
   *
   * @return OK
   */
  addItemsListEntryUsingPOSTResponse(params: ItemsListControllerService.AddItemsListEntryUsingPOSTParams): __Observable<__StrictHttpResponse<ItemsListEntry>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.itemsListEntry;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/itemslist/${params.listname}/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemsListEntry>;
      })
    );
  }
  /**
   * @param params The `ItemsListControllerService.AddItemsListEntryUsingPOSTParams` containing the following parameters:
   *
   * - `listname`: listname
   *
   * - `itemsListEntry`: itemsListEntry
   *
   * @return OK
   */
  addItemsListEntryUsingPOST(params: ItemsListControllerService.AddItemsListEntryUsingPOSTParams): __Observable<ItemsListEntry> {
    return this.addItemsListEntryUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ItemsListEntry)
    );
  }
}

module ItemsListControllerService {

  /**
   * Parameters for findListItemsUsingGET
   */
  export interface FindListItemsUsingGETParams {

    /**
     * listname
     */
    listname: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for addItemsListEntryUsingPOST
   */
  export interface AddItemsListEntryUsingPOSTParams {

    /**
     * listname
     */
    listname: string;

    /**
     * itemsListEntry
     */
    itemsListEntry: ItemsListEntry;
  }
}

export { ItemsListControllerService }
