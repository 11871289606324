import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * User Controller
 */
class UserControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param token token
     */
    activateUserUsingGETResponse(token) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (token != null)
            __params = __params.set('token', token.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/user-pub-api/activate-user`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param token token
     */
    activateUserUsingGET(token) {
        return this.activateUserUsingGETResponse(token).pipe(__map(_r => _r.body));
    }
}
UserControllerService.ngInjectableDef = i0.defineInjectable({ factory: function UserControllerService_Factory() { return new UserControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserControllerService, providedIn: "root" });
export { UserControllerService };
