import * as tslib_1 from "tslib";
import { Action, Select, State, Store } from '@ngxs/store';
import { AccountsState } from '../../../store/accounts.state';
import { Observable } from 'rxjs/Observable';
import { filter, map, take, tap } from 'rxjs/operators';
import { AccountRestControllerService } from '@api/services/account-rest-controller.service';
import { AdminSiteRestControllerService } from '@api/services/admin-site-rest-controller.service';
import { ColaborationControllerService } from '@api/services/colaboration-controller.service';
export class LoadSites {
}
LoadSites.type = '[Sites] Load sites';
export class LoadSharedSites {
}
LoadSharedSites.type = '[Sites] Load sharedsites';
export class LoadSitesSuccess {
    constructor(payload) {
        this.payload = payload;
    }
}
LoadSitesSuccess.type = '[Sites] Load sites success';
export class LoadSitesFailure {
    constructor(payload) {
        this.payload = payload;
    }
}
LoadSitesFailure.type = '[Sites] Load failed';
export class CreateSite {
    constructor(payload) {
        this.payload = payload;
    }
}
CreateSite.type = '[Sites] Create site';
export class DeleteSite {
    constructor(payload) {
        this.payload = payload;
    }
}
DeleteSite.type = '[Sites] Delete site';
export class LeaveSite {
    constructor(payload) {
        this.payload = payload;
    }
}
LeaveSite.type = '[Sites] Leave site';
let SitesState = class SitesState {
    constructor(adminSiteService, accountRestControllerService, colaborationService, store) {
        this.adminSiteService = adminSiteService;
        this.accountRestControllerService = accountRestControllerService;
        this.colaborationService = colaborationService;
        this.store = store;
        this.selectedAccount$
            .pipe(filter(value => !!value), take(1))
            .subscribe(value => {
            this.selectedAccount = value;
            this.store.dispatch(new LoadSites());
            this.store.dispatch(new LoadSharedSites());
        });
    }
    loadSites(sc) {
        sc.patchState({ loading: true });
        return this.accountRestControllerService.findAllUsingGET1(this.selectedAccount.uid).pipe(map(value => {
            sc.dispatch(new LoadSitesSuccess(value));
        }));
    }
    loadSharedSites(ctx) {
        ctx.patchState({ loading: true });
        return this.colaborationService.getInvitedSitesUsingGET().pipe(tap(value => {
            ctx.setState(Object.assign({}, ctx.getState(), { shared: value }));
        }));
    }
    loadSiteSuccess(sc, { payload }) {
        return sc.setState(Object.assign({}, sc.getState(), { list: payload, loading: false }));
    }
    createSIte(sc, { payload }) {
        return this.adminSiteService.createSiteUsingPOST(payload).pipe(map(value => {
            const state = sc.getState();
            sc.setState(Object.assign({}, state, { list: [...state.list, value] }));
        }));
    }
    deleteSite(sc, { payload }) {
        return this.adminSiteService
            .deleteSiteUsingDELETE(payload.uid)
            .toPromise()
            .then(value => {
            const state = sc.getState();
            state.list = state.list.filter(val => val.uid !== payload.uid);
            sc.setState(Object.assign({}, state));
        });
    }
    leaveSite(sc, { payload }) {
        return this.colaborationService.leaveSiteUsingPOST(payload.uid).pipe(map(value => {
            sc.dispatch(new LoadSharedSites());
        }));
    }
};
tslib_1.__decorate([
    Select(AccountsState.selectedAccount),
    tslib_1.__metadata("design:type", Observable)
], SitesState.prototype, "selectedAccount$", void 0);
tslib_1.__decorate([
    Action(LoadSites),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "loadSites", null);
tslib_1.__decorate([
    Action(LoadSharedSites),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "loadSharedSites", null);
tslib_1.__decorate([
    Action(LoadSitesSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "loadSiteSuccess", null);
tslib_1.__decorate([
    Action(CreateSite),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "createSIte", null);
tslib_1.__decorate([
    Action(DeleteSite),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "deleteSite", null);
tslib_1.__decorate([
    Action(LeaveSite),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, Object]),
    tslib_1.__metadata("design:returntype", void 0)
], SitesState.prototype, "leaveSite", null);
SitesState = tslib_1.__decorate([
    State({
        name: 'sites',
        defaults: {
            list: [],
            shared: [],
            loading: false,
            error: null
        }
    }),
    tslib_1.__metadata("design:paramtypes", [AdminSiteRestControllerService,
        AccountRestControllerService,
        ColaborationControllerService,
        Store])
], SitesState);
export { SitesState };
