import * as tslib_1 from "tslib";
import { AuthService } from '../../../core/auth.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { LoginModel } from '../state/auth.state';
import { Store } from '@ngxs/store';
import { Login } from '../state/auth.actions';
import { Router } from '@angular/router';
export class LoginComponent {
    constructor(router, auth, formBuilder, store) {
        this.router = router;
        this.auth = auth;
        this.formBuilder = formBuilder;
        this.store = store;
        this.isError = false;
        this.loginForm = this.formBuilder.formGroup(new LoginModel());
        this.auth.logout();
    }
    submitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isError = false;
            try {
                yield this.store.dispatch(new Login()).toPromise();
            }
            catch (error) {
                this.isError = true;
            }
        });
    }
    startResetPassword() {
        this.router.navigate(['auth/send-password-token']);
    }
}
