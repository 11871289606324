import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../primeng.module';
import { TabsComponent } from './tabs/tabs.component';
import { LogoComponent } from './logo/logo.component';
import { ButtonWithLabelComponent } from './button-with-label/button-with-label.component';
import { AppVersionComponent } from './app-version/app-version.component';
import { TreeComponent } from './tree/tree.component';
import { FileUploadComponent } from '@smart-html-panel/ui-library/src/lib/components/file-upload/file-upload.component';
import { MonacoEditorComponent } from './monaco-editor/monaco-editor.component';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { FormsModule } from '@angular/forms';

const COMPONENTS = [
  TabsComponent,
  LogoComponent,
  ButtonWithLabelComponent,
  AppVersionComponent,
  TreeComponent,
  FileUploadComponent,
  MonacoEditorComponent
];

@NgModule({
  imports: [CommonModule, PrimengModule, MonacoEditorModule, FormsModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS]
})
export class ComponentsModule {}
