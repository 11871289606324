/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./logo.component";
var styles_LogoComponent = ["[_nghost-%COMP%] {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n\n    h1[_ngcontent-%COMP%] {\n      margin: 0;\n      font-size: 25px;\n      line-height: 3rem;\n    }"];
var RenderType_LogoComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LogoComponent, data: {} });
export { RenderType_LogoComponent as RenderType_LogoComponent };
export function View_LogoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "h1", [["class", "sh sh-logo-simple"]], null, null, null, null, null))], null, null); }
export function View_LogoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sh-logo", [], null, null, null, View_LogoComponent_0, RenderType_LogoComponent)), i0.ɵdid(1, 114688, null, 0, i1.LogoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoComponentNgFactory = i0.ɵccf("sh-logo", i1.LogoComponent, View_LogoComponent_Host_0, {}, {}, []);
export { LogoComponentNgFactory as LogoComponentNgFactory };
