import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth.service';
import { prop, required, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Store } from '@ngxs/store';
import { SendPasswordLinkAction } from '../state/auth.actions';
import { SendPasswordTokenModel } from '@api/models/send-password-token-model';

@Component({
  selector: 'app-send-password-token',
  templateUrl: './send-password-token.component.html',
  styleUrls: ['./send-password-token.component.scss']
})
export class SendPasswordTokenComponent implements OnInit {
  isError = false;
  model: SendPasswordTokenModel = {
    email: ''
  };

  constructor(private readonly router: Router, private auth: AuthService, private store: Store) {}

  ngOnInit() {}

  async submitForm() {
    this.isError = false;

    try {
      await this.store.dispatch(new SendPasswordLinkAction(this.model)).toPromise();
    } catch (error) {
      this.isError = true;
    }
  }

  backToLogin() {
    this.router.navigate(['auth/login']);
  }
}
