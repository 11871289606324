<h3>Password reset</h3>
<form (submit)="submitForm()" class="login-form p-grid">
  <label>Enter your email:</label>
  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-user"></span>
      <input
        pInputText
        name="email"
        [(ngModel)]="model.email"
        placeholder="email"
        id="username"
        type="text"
        #email="ngModel"
        [class.ui-state-error]="isError"
      />
      <span *ngIf="isError" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <p>We'll email instructions on how to reset your password.</p>

  <div class="p-grid">
    <div class="p-col-4">
      <button pButton (click)="backToLogin()" label="Back" class="ui-button-secondary"></button>
    </div>

    <div class="p-col-8"><button pButton type="submit" label="Reset password" class="ui-button-primary"></button></div>
  </div>
</form>
