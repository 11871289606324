import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MenubarModule } from 'primeng/menubar';
import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { DataViewModule } from 'primeng/dataview';
import { BlockableUI, ConfirmationService, MessageService } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { DataListModule } from 'primeng/datalist';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ProgressBarModule } from 'primeng/progressbar';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { PanelModule } from 'primeng/panel';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { ToolbarModule } from 'primeng/toolbar';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { BlockUIModule } from 'primeng/blockui';

const PrimeNgModules = [
  ButtonModule,
  DataListModule,
  SidebarModule,
  DialogModule,
  ConfirmDialogModule,
  MenubarModule,
  CardModule,
  MessagesModule,
  MessageModule,
  DropdownModule,
  DataViewModule,
  FileUploadModule,
  TreeModule,
  CodeHighlighterModule,
  ProgressBarModule,
  InputTextModule,
  TabViewModule,
  TabMenuModule,
  PanelModule,
  ScrollPanelModule,
  TooltipModule,
  ToolbarModule,
  CheckboxModule,
  InputTextareaModule,
  OverlayPanelModule,
  TableModule,
  ToastModule,
  AutoCompleteModule,
  ProgressSpinnerModule,
  DynamicDialogModule,
  BlockUIModule
];

@NgModule({
  imports: [CommonModule, ...PrimeNgModules],
  exports: [...PrimeNgModules],
  providers: [ConfirmationService, MessageService]
})
export class PrimengModule {}
