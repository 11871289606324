import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-send-password-token-success',
  templateUrl: './send-password-token-success.component.html',
  styleUrls: ['./send-password-token-success.component.scss']
})
export class SendPasswordTokenSuccessComponent implements OnInit {
  constructor(private readonly router: Router) {}

  ngOnInit() {}

  backToLogin() {
    this.router.navigate(['auth/login']);
  }
}
