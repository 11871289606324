/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./send-password-token-success.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "primeng/components/button/button";
import * as i3 from "./send-password-token-success.component";
import * as i4 from "@angular/router";
var styles_SendPasswordTokenSuccessComponent = [i0.styles];
var RenderType_SendPasswordTokenSuccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SendPasswordTokenSuccessComponent, data: {} });
export { RenderType_SendPasswordTokenSuccessComponent as RenderType_SendPasswordTokenSuccessComponent };
export function View_SendPasswordTokenSuccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h3", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Successfully sent"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your password reset link has been sent by email"])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "p-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "ui-button-secondary"], ["label", "Back to login"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 4341760, null, 0, i2.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Back to login"; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_SendPasswordTokenSuccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-send-password-token-success", [], null, null, null, View_SendPasswordTokenSuccessComponent_0, RenderType_SendPasswordTokenSuccessComponent)), i1.ɵdid(1, 114688, null, 0, i3.SendPasswordTokenSuccessComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SendPasswordTokenSuccessComponentNgFactory = i1.ɵccf("app-send-password-token-success", i3.SendPasswordTokenSuccessComponent, View_SendPasswordTokenSuccessComponent_Host_0, {}, {}, []);
export { SendPasswordTokenSuccessComponentNgFactory as SendPasswordTokenSuccessComponentNgFactory };
