import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './authentication.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SignupSuccesComponent } from './signup-succes/signup-succes.component';
import { UiLibraryModule } from '@smart-html/ui-library';
import { RecaptchaCommonModule } from 'ng-recaptcha/recaptcha/recaptcha-common.module';
import { FormsModule } from '@angular/forms';
import { SamePasswordDirective } from './same-password.directive';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './state/auth.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { SendPasswordTokenComponent } from './send-password-token/send-password-token.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SendPasswordTokenSuccessComponent } from './send-password-token-success/send-password-token-success.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UiLibraryModule,
    RecaptchaCommonModule,
    AuthenticationRoutingModule,
    NgxsModule.forFeature([AuthState]),
    NgxsFormPluginModule
  ],
  declarations: [
    AuthenticationComponent,
    LoginComponent,
    SignupComponent,
    SignupSuccesComponent,
    SamePasswordDirective,
    SendPasswordTokenComponent,
    ResetPasswordComponent,
    SendPasswordTokenSuccessComponent,
    ResetPasswordSuccessComponent
  ]
})
export class AuthenticationModule {}
