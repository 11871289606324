import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AccountRestControllerService } from '@api/services/account-rest-controller.service';
import { Account } from '@api/models/account';

export interface IAccountsStateModel {
  list: Account[];
  selected: Account;
  loading: boolean;
  error: any;
}

export class LoadAccounts {
  static readonly type = '[Accounts] Load accounts';
}

export class LoadAccountsSuccess {
  static readonly type = '[Accounts] Load accounts success';
  constructor(public payload: Account[]) {}
}

export class SelectAccount {
  static readonly type = '[Accounts] Select account';
  constructor(public payload: Account) {}
}

@State<IAccountsStateModel>({
  name: 'accounts',
  defaults: {
    list: [],
    selected: null,
    loading: false,
    error: null
  }
})
export class AccountsState {
  constructor(private accountRestControllerService: AccountRestControllerService) {}

  @Selector()
  static accounts(state: IAccountsStateModel) {
    return state.list;
  }

  @Selector()
  static selectedAccount(state: IAccountsStateModel) {
    return state.selected || '';
  }

  @Action(LoadAccounts)
  loadAccounts(sc: StateContext<IAccountsStateModel>) {
    sc.patchState({ loading: true });

    this.accountRestControllerService.findAllUsingGET().subscribe(value => {
      return sc.dispatch(new LoadAccountsSuccess(value));
    });
  }

  @Action(LoadAccountsSuccess)
  loadAccountsSuccess(sc: StateContext<IAccountsStateModel>, { payload }: LoadAccountsSuccess) {
    sc.setState({
      ...sc.getState(),
      list: payload,
      loading: false
    });
    return sc.dispatch(new SelectAccount(payload[0]));
  }

  @Action(SelectAccount)
  selectAccount(sc: StateContext<IAccountsStateModel>, { payload }: SelectAccount) {
    sc.setState({
      ...sc.getState(),
      selected: payload
    });
  }
}
