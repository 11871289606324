<form (submit)="captchaRef.execute()" class="login-form p-grid">
  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-user"></span>
      <input
        pInputText
        [(ngModel)]="model.accountName"
        required
        placeholder="Account name"
        id="accountName"
        name="accountName"
        type="text"
        #accountName="ngModel"
        [class.ui-state-error]="isRequired(accountName)"
      />
      <span *ngIf="isRequired(accountName)" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-mail"></span>
      <input
        pInputText
        [(ngModel)]="model.email"
        required
        placeholder="Email"
        id="email"
        name="email"
        type="text"
        #email="ngModel"
        [class.ui-state-error]="isRequired(email)"
      />
      <span *ngIf="isRequired(email)" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-password"></span>
      <input
        pInputText
        [(ngModel)]="model.accessCode"
        required
        placeholder="Access code"
        id="accessCode"
        name="accessCode"
        type="text"
        #accessCode="ngModel"
        [class.ui-state-error]="isRequired(accessCode)"
      />
      <span *ngIf="isRequired(accessCode)" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-password"></span>
      <input
        pInputText
        [(ngModel)]="model.password"
        required
        placeholder="Password"
        id="password"
        name="password"
        type="password"
        #password="ngModel"
        [class.ui-state-error]="isRequired(password)"
      />
      <span *ngIf="isRequired(password)" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-repeat-password"></span>
      <input
        pInputText
        [(ngModel)]="model.password2"
        required
        placeholder="Repeat password"
        id="password2"
        name="password2"
        type="password"
        #password2="ngModel"
        appSamePassword
        [class.ui-state-error]="
          isRequired(password2) ||
          (password2.invalid && (password2.dirty || password2.touched) && password2.errors['notFit'])
        "
      />
      <span
        *ngIf="
          isRequired(password2) ||
          (password2.invalid && (password2.dirty || password2.touched) && password2.errors['notFit'])
        "
        class="sh sh-validation sh-error"
      ></span>
    </div>
    <p-message
      *ngIf="password2.invalid && (password2.dirty || password2.touched) && password2.errors['notFit']"
      severity="error"
      text=" Passwords don't fit."
    >
    </p-message>
  </div>

  <div class="p-col">
    <re-captcha
      (resolved)="resolved($event)"
      siteKey="6Le96HAUAAAAAFv0lgzvb60Xe1FT6AcWGxGQvMNM"
      size="invisible"
      #captchaRef="reCaptcha"
    ></re-captcha>
  </div>

  <div class="p-col"><button pButton type="submit" label="Signup" class="ui-button-success"></button></div>
</form>

<p-message *ngIf="isError" severity="error" text="Authentication Failed: Bad credentials"></p-message>
<p-message *ngIf="notFilled" severity="error" text="Provide all data"></p-message>
