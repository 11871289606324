import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Collection Controller
 */
class CollectionControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param collectionTypeName collectionTypeName
     * @return OK
     */
    findCollectionByTypeUsingGETResponse(collectionTypeName) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection/${collectionTypeName}/version`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param collectionTypeName collectionTypeName
     * @return OK
     */
    findCollectionByTypeUsingGET(collectionTypeName) {
        return this.findCollectionByTypeUsingGETResponse(collectionTypeName).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `CollectionControllerService.AddCollectionUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collection`: collection
     *
     * @return OK
     */
    addCollectionUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.collection;
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/version`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `CollectionControllerService.AddCollectionUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collection`: collection
     *
     * @return OK
     */
    addCollectionUsingPOST(params) {
        return this.addCollectionUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `CollectionControllerService.GetCollectionUsingGETParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    getCollectionUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/version/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `CollectionControllerService.GetCollectionUsingGETParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    getCollectionUsingGET(params) {
        return this.getCollectionUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `CollectionControllerService.UpdateCollectionUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collection`: collection
     *
     * @return OK
     */
    updateCollectionUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.collection;
        let req = new HttpRequest('POST', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/version/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `CollectionControllerService.UpdateCollectionUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collection`: collection
     *
     * @return OK
     */
    updateCollectionUsingPOST(params) {
        return this.updateCollectionUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `CollectionControllerService.DeleteCollectionUsingDELETEParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     */
    deleteCollectionUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/version/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `CollectionControllerService.DeleteCollectionUsingDELETEParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `collectionTypeName`: collectionTypeName
     */
    deleteCollectionUsingDELETE(params) {
        return this.deleteCollectionUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
}
CollectionControllerService.ngInjectableDef = i0.defineInjectable({ factory: function CollectionControllerService_Factory() { return new CollectionControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: CollectionControllerService, providedIn: "root" });
export { CollectionControllerService };
