import { Component } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { IVersionData } from '@smart-html-panel/ui-library/src/lib/components/app-version/app-version.component';
import { VERSION } from '../environments/version';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  versionData: IVersionData = VERSION;

  constructor(private googleAnalytics: Angulartics2GoogleAnalytics) {
    googleAnalytics.startTracking();
  }
}
