/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Item } from '../models/item';
import { ItemFormSection } from '../models/item-form-section';
import { ItemMetadata } from '../models/item-metadata';
import { ResponseEntity } from '../models/response-entity';
import { TreeNode } from '../models/tree-node';
import { Collection } from '../models/collection';

/**
 * Item Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class ItemRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ItemRestControllerService.ListItemsUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  listItemsUsingGETResponse(params: ItemRestControllerService.ListItemsUsingGETParams): __Observable<__StrictHttpResponse<Array<Item>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/items/data/${params.collectionTypeName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Item>>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.ListItemsUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  listItemsUsingGET(params: ItemRestControllerService.ListItemsUsingGETParams): __Observable<Array<Item>> {
    return this.listItemsUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<Item>)
    );
  }

  /**
   * @param params The `ItemRestControllerService.SaveDataUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveDataUsingPOSTResponse(params: ItemRestControllerService.SaveDataUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/data/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.SaveDataUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveDataUsingPOST(params: ItemRestControllerService.SaveDataUsingPOSTParams): __Observable<string> {
    return this.saveDataUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.CreateFolderUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  createFolderUsingPOSTResponse(params: ItemRestControllerService.CreateFolderUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/folder/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.CreateFolderUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  createFolderUsingPOST(params: ItemRestControllerService.CreateFolderUsingPOSTParams): __Observable<string> {
    return this.createFolderUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.DeleteFolderUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `keepRoot`: keepRoot
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  deleteFolderUsingDELETEResponse(params: ItemRestControllerService.DeleteFolderUsingDELETEParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.keepRoot != null) __params = __params.set('keepRoot', params.keepRoot.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/items/folder/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.DeleteFolderUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `keepRoot`: keepRoot
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  deleteFolderUsingDELETE(params: ItemRestControllerService.DeleteFolderUsingDELETEParams): __Observable<string> {
    return this.deleteFolderUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.GetFormModelUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  getFormModelUsingGETResponse(params: ItemRestControllerService.GetFormModelUsingGETParams): __Observable<__StrictHttpResponse<ItemFormSection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/items/formmodel/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemFormSection>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.GetFormModelUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  getFormModelUsingGET(params: ItemRestControllerService.GetFormModelUsingGETParams): __Observable<ItemFormSection> {
    return this.getFormModelUsingGETResponse(params).pipe(
      __map(_r => _r.body as ItemFormSection)
    );
  }

  /**
   * @param params The `ItemRestControllerService.SaveFormModelUsingPOSTParams` containing the following parameters:
   *
   * - `itemFormSection`: itemFormSection
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveFormModelUsingPOSTResponse(params: ItemRestControllerService.SaveFormModelUsingPOSTParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.itemFormSection;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/formmodel/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.SaveFormModelUsingPOSTParams` containing the following parameters:
   *
   * - `itemFormSection`: itemFormSection
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveFormModelUsingPOST(params: ItemRestControllerService.SaveFormModelUsingPOSTParams): __Observable<{}> {
    return this.saveFormModelUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  parseMetadataStringUsingPOSTResponse(revisionTag?: string): __Observable<__StrictHttpResponse<ItemMetadata>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (revisionTag != null) __params = __params.set('revisionTag', revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/metadata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ItemMetadata>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  parseMetadataStringUsingPOST(revisionTag?: string): __Observable<ItemMetadata> {
    return this.parseMetadataStringUsingPOSTResponse(revisionTag).pipe(
      __map(_r => _r.body as ItemMetadata)
    );
  }

  /**
   * @param params The `ItemRestControllerService.ParseMetadataUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  parseMetadataUsingGETResponse(params: ItemRestControllerService.ParseMetadataUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/items/metadata/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.ParseMetadataUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  parseMetadataUsingGET(params: ItemRestControllerService.ParseMetadataUsingGETParams): __Observable<string> {
    return this.parseMetadataUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.GetResourceUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  getResourceUsingGETResponse(params: ItemRestControllerService.GetResourceUsingGETParams): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.GetResourceUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  getResourceUsingGET(params: ItemRestControllerService.GetResourceUsingGETParams): __Observable<ResponseEntity> {
    return this.getResourceUsingGETResponse(params).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }

  /**
   * @param params The `ItemRestControllerService.SaveResourceUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveResourceUsingPOSTResponse(params: ItemRestControllerService.SaveResourceUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.SaveResourceUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  saveResourceUsingPOST(params: ItemRestControllerService.SaveResourceUsingPOSTParams): __Observable<string> {
    return this.saveResourceUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.DeleteResourceUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  deleteResourceUsingDELETEResponse(params: ItemRestControllerService.DeleteResourceUsingDELETEParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.DeleteResourceUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `filePath`: filePath
   *
   * @return OK
   */
  deleteResourceUsingDELETE(params: ItemRestControllerService.DeleteResourceUsingDELETEParams): __Observable<string> {
    return this.deleteResourceUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ItemRestControllerService.GetAssetsTreeUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `parentFolderId`: parentFolderId
   *
   * - `depth`: depth
   *
   * @return OK
   */
  getAssetsTreeUsingGETResponse(params: ItemRestControllerService.GetAssetsTreeUsingGETParams): __Observable<__StrictHttpResponse<Array<TreeNode>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.parentFolderId != null) __params = __params.set('parentFolderId', params.parentFolderId.toString());
    if (params.depth != null) __params = __params.set('depth', params.depth.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/items/tree/${params.collectionTypeName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TreeNode>>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.GetAssetsTreeUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * - `parentFolderId`: parentFolderId
   *
   * - `depth`: depth
   *
   * @return OK
   */
  getAssetsTreeUsingGET(params: ItemRestControllerService.GetAssetsTreeUsingGETParams): __Observable<Array<TreeNode>> {
    return this.getAssetsTreeUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<TreeNode>)
    );
  }

  /**
   * @param params The `ItemRestControllerService.UploadThemeUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  uploadThemeUsingPOSTResponse(params: ItemRestControllerService.UploadThemeUsingPOSTParams): __Observable<__StrictHttpResponse<Collection>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __headers.append('Content-Type', 'multipart/form-data');
    let __formData = new FormData();
    __body = __formData;
   if(params.file !== null && typeof params.file !== "undefined") { __formData.append('file', params.file as string | Blob);}

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/items/zip/${params.collectionTypeName}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Collection>;
      })
    );
  }
  /**
   * @param params The `ItemRestControllerService.UploadThemeUsingPOSTParams` containing the following parameters:
   *
   * - `file`: file
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  uploadThemeUsingPOST(params: ItemRestControllerService.UploadThemeUsingPOSTParams): __Observable<Collection> {
    return this.uploadThemeUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Collection)
    );
  }
}

module ItemRestControllerService {

  /**
   * Parameters for listItemsUsingGET
   */
  export interface ListItemsUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for saveDataUsingPOST
   */
  export interface SaveDataUsingPOSTParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for createFolderUsingPOST
   */
  export interface CreateFolderUsingPOSTParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for deleteFolderUsingDELETE
   */
  export interface DeleteFolderUsingDELETEParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * keepRoot
     */
    keepRoot?: boolean;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for getFormModelUsingGET
   */
  export interface GetFormModelUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for saveFormModelUsingPOST
   */
  export interface SaveFormModelUsingPOSTParams {

    /**
     * itemFormSection
     */
    itemFormSection: ItemFormSection;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for parseMetadataUsingGET
   */
  export interface ParseMetadataUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for getResourceUsingGET
   */
  export interface GetResourceUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for saveResourceUsingPOST
   */
  export interface SaveResourceUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for deleteResourceUsingDELETE
   */
  export interface DeleteResourceUsingDELETEParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * filePath
     */
    filePath?: string;
  }

  /**
   * Parameters for getAssetsTreeUsingGET
   */
  export interface GetAssetsTreeUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * parentFolderId
     */
    parentFolderId?: number;

    /**
     * depth
     */
    depth?: number;
  }

  /**
   * Parameters for uploadThemeUsingPOST
   */
  export interface UploadThemeUsingPOSTParams {

    /**
     * file
     */
    file: Blob;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }
}

export { ItemRestControllerService }
