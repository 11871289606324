import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsConfig, SignupModel } from '../../../../../../../libs/api-sdk/models';
import { AuthService } from '../../../core/auth.service';

export interface ISignUpForm extends SignupModel {
  password2: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html'
})
export class SignupComponent implements OnInit {
  isError = false;
  notFilled = false;
  recaptchaResponse: string;

  model: ISignUpForm = {
    password2: ''
  };

  constructor(private readonly router: Router, private auth: AuthService) {}

  ngOnInit() {}

  submitForm() {
    this.isError = false;

    if (
      this.model.email &&
      this.model.password &&
      this.model.password === this.model.password2 &&
      this.model.accountName &&
      this.recaptchaResponse &&
      this.model.accessCode
    ) {
      this.notFilled = false;

      this.auth
        .signUp({
          accountName: this.model.accountName,
          displayName: this.model.accountName,
          email: this.model.email,
          password: this.model.password,
          recapchaResponse: this.recaptchaResponse,
          accessCode: this.model.accessCode
        })
        .then((response: CredentialsConfig) => {
          console.log(response);
          this.router.navigate(['auth/signup-success']);
        })
        .catch(err => {
          console.log(err);
          this.isError = true;
        });
    } else {
      this.notFilled = true;
    }
  }

  isRequired(name) {
    return name.invalid && (name.dirty || name.touched) && name.errors.required;
  }

  resolved(captchaResponse: string) {
    this.recaptchaResponse = captchaResponse;
    this.submitForm();
  }
}
