/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SyncConfigModel } from '../models/sync-config-model';
import { ResponseEntity } from '../models/response-entity';
import { RenderItem } from '../models/render-item';
import { Item } from '../models/item';

/**
 * Sync Controller
 */
@Injectable({
  providedIn: 'root',
})
class SyncControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  getSyncModelUsingGETResponse(): __Observable<__StrictHttpResponse<SyncConfigModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/sync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyncConfigModel>;
      })
    );
  }
  /**
   * @return OK
   */
  getSyncModelUsingGET(): __Observable<SyncConfigModel> {
    return this.getSyncModelUsingGETResponse().pipe(
      __map(_r => _r.body as SyncConfigModel)
    );
  }

  /**
   * @param syncConfigModel syncConfigModel
   * @return OK
   */
  setSyncModelUsingPOSTResponse(syncConfigModel: SyncConfigModel): __Observable<__StrictHttpResponse<SyncConfigModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = syncConfigModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/sync`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SyncConfigModel>;
      })
    );
  }
  /**
   * @param syncConfigModel syncConfigModel
   * @return OK
   */
  setSyncModelUsingPOST(syncConfigModel: SyncConfigModel): __Observable<SyncConfigModel> {
    return this.setSyncModelUsingPOSTResponse(syncConfigModel).pipe(
      __map(_r => _r.body as SyncConfigModel)
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  pullListUsingGETResponse(revisionTag?: string): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (revisionTag != null) __params = __params.set('revisionTag', revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/sync/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  pullListUsingGET(revisionTag?: string): __Observable<Array<string>> {
    return this.pullListUsingGETResponse(revisionTag).pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * @param params The `SyncControllerService.PullUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `collectionId`: collectionId
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  pullUsingGETResponse(params: SyncControllerService.PullUsingGETParams): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/sync/item/download/${params.collectionId}/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * @param params The `SyncControllerService.PullUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `collectionId`: collectionId
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  pullUsingGET(params: SyncControllerService.PullUsingGETParams): __Observable<ResponseEntity> {
    return this.pullUsingGETResponse(params).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }

  /**
   * @param params The `SyncControllerService.PushUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  pushUsingPOSTResponse(params: SyncControllerService.PushUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/sync/item/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SyncControllerService.PushUsingPOSTParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  pushUsingPOST(params: SyncControllerService.PushUsingPOSTParams): __Observable<string> {
    return this.pushUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `SyncControllerService.DeleteUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  deleteUsingDELETEResponse(params: SyncControllerService.DeleteUsingDELETEParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/sync/item/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `SyncControllerService.DeleteUsingDELETEParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  deleteUsingDELETE(params: SyncControllerService.DeleteUsingDELETEParams): __Observable<string> {
    return this.deleteUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param item item
   * @return OK
   */
  processItemUsingPOSTResponse(item: Item): __Observable<__StrictHttpResponse<Array<RenderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = item;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/sync/process`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RenderItem>>;
      })
    );
  }
  /**
   * @param item item
   * @return OK
   */
  processItemUsingPOST(item: Item): __Observable<Array<RenderItem>> {
    return this.processItemUsingPOSTResponse(item).pipe(
      __map(_r => _r.body as Array<RenderItem>)
    );
  }

  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  getRenderItemsUsingGET1Response(revisionTag?: string): __Observable<__StrictHttpResponse<Array<RenderItem>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (revisionTag != null) __params = __params.set('revisionTag', revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/sync/renderitems`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<RenderItem>>;
      })
    );
  }
  /**
   * @param revisionTag revisionTag
   * @return OK
   */
  getRenderItemsUsingGET1(revisionTag?: string): __Observable<Array<RenderItem>> {
    return this.getRenderItemsUsingGET1Response(revisionTag).pipe(
      __map(_r => _r.body as Array<RenderItem>)
    );
  }
}

module SyncControllerService {

  /**
   * Parameters for pullUsingGET
   */
  export interface PullUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * collectionId
     */
    collectionId: number;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for pushUsingPOST
   */
  export interface PushUsingPOSTParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for deleteUsingDELETE
   */
  export interface DeleteUsingDELETEParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }
}

export { SyncControllerService }
