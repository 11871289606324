import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Sync Controller
 */
class SyncControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    getSyncModelUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/sync`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    getSyncModelUsingGET() {
        return this.getSyncModelUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param syncConfigModel syncConfigModel
     * @return OK
     */
    setSyncModelUsingPOSTResponse(syncConfigModel) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = syncConfigModel;
        let req = new HttpRequest('POST', this.rootUrl + `/api/sync`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param syncConfigModel syncConfigModel
     * @return OK
     */
    setSyncModelUsingPOST(syncConfigModel) {
        return this.setSyncModelUsingPOSTResponse(syncConfigModel).pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    pullListUsingGETResponse(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (revisionTag != null)
            __params = __params.set('revisionTag', revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/sync/item`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    pullListUsingGET(revisionTag) {
        return this.pullListUsingGETResponse(revisionTag).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `SyncControllerService.PullUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collectionId`: collectionId
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    pullUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/sync/item/download/${params.collectionId}/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `SyncControllerService.PullUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `collectionId`: collectionId
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    pullUsingGET(params) {
        return this.pullUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `SyncControllerService.PushUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    pushUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/sync/item/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `SyncControllerService.PushUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    pushUsingPOST(params) {
        return this.pushUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `SyncControllerService.DeleteUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    deleteUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/sync/item/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `SyncControllerService.DeleteUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    deleteUsingDELETE(params) {
        return this.deleteUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param item item
     * @return OK
     */
    processItemUsingPOSTResponse(item) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = item;
        let req = new HttpRequest('POST', this.rootUrl + `/api/sync/process`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param item item
     * @return OK
     */
    processItemUsingPOST(item) {
        return this.processItemUsingPOSTResponse(item).pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    getRenderItemsUsingGET1Response(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (revisionTag != null)
            __params = __params.set('revisionTag', revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/sync/renderitems`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    getRenderItemsUsingGET1(revisionTag) {
        return this.getRenderItemsUsingGET1Response(revisionTag).pipe(__map(_r => _r.body));
    }
}
SyncControllerService.ngInjectableDef = i0.defineInjectable({ factory: function SyncControllerService_Factory() { return new SyncControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: SyncControllerService, providedIn: "root" });
export { SyncControllerService };
