<div class="wrapper">
  <header class="main-header p-grid p-align-center">
    <div class="p-col p-col-align-center"><p-tabMenu [model]="menu"></p-tabMenu></div>
    <div class="p-col"><app-profile [account]="selectedAccount$ | async" (logout)="logout()"></app-profile></div>
  </header>
  <aside class="left-side"><sh-logo></sh-logo></aside>

  <section class="main-content">
    <div class="main-content__inner"><router-outlet></router-outlet></div>
  </section>
</div>
