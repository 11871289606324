import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * User Rest Controller
 */
class UserRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param resetPasswordModel resetPasswordModel
     */
    resetPasswordUsingPOSTResponse(resetPasswordModel) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = resetPasswordModel;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/reset-password`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param resetPasswordModel resetPasswordModel
     */
    resetPasswordUsingPOST(resetPasswordModel) {
        return this.resetPasswordUsingPOSTResponse(resetPasswordModel).pipe(__map(_r => _r.body));
    }
    /**
     * @param sendPasswordTokenModel sendPasswordTokenModel
     */
    sendPasswordTokenUsingPOSTResponse(sendPasswordTokenModel) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = sendPasswordTokenModel;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/send-password-token`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param sendPasswordTokenModel sendPasswordTokenModel
     */
    sendPasswordTokenUsingPOST(sendPasswordTokenModel) {
        return this.sendPasswordTokenUsingPOSTResponse(sendPasswordTokenModel).pipe(__map(_r => _r.body));
    }
    /**
     * @param signupModel signupModel
     * @return OK
     */
    signUpUsingPOSTResponse(signupModel) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = signupModel;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/sign-up`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param signupModel signupModel
     * @return OK
     */
    signUpUsingPOST(signupModel) {
        return this.signUpUsingPOSTResponse(signupModel).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    getCurrentUserUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/user-api/user`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    getCurrentUserUsingGET() {
        return this.getCurrentUserUsingGETResponse().pipe(__map(_r => _r.body));
    }
}
UserRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function UserRestControllerService_Factory() { return new UserRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: UserRestControllerService, providedIn: "root" });
export { UserRestControllerService };
