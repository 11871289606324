import { AppComponent } from './app.component';
import { APP_INITIALIZER, NgModule, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PublicModule } from './public/public.module';
import { PrivateModule } from './private/private.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AuthInterceptor } from './core/auth.interceptor';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AccountsState } from './store/accounts.state';
import { ApiConfiguration } from '@api/api-configuration';
import { ApiModule } from '@api/api.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RecaptchaModule } from 'ng-recaptcha';
import { UiLibraryModule } from '@smart-html/ui-library';
import { Angulartics2Module } from 'angulartics2';
import { AppState } from './store/app.state';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/private',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/private',
    pathMatch: 'full'
  }
];

export function initApiConfiguration(config: ApiConfiguration): Function {
  return () => {
    config.rootUrl = '';
  };
}

export const INIT_API_CONFIGURATION: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initApiConfiguration,
  deps: [ApiConfiguration],
  multi: true
};

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    RouterModule.forRoot(ROUTES, {
      // enableTracing: true
      useHash: true
    }),
    NgxsModule.forRoot([AccountsState, AppState]),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.expirationDate']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      /**
       * Disable the devtools, useful to disabling during production
       */
      disabled: false
    }),
    NgxsFormPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    Angulartics2Module.forRoot(),
    RecaptchaModule.forRoot(),
    UiLibraryModule,
    PublicModule,
    PrivateModule,
    CoreModule,
    ApiModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [INIT_API_CONFIGURATION, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }]
})
export class AppModule {}
