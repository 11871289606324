import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Content Type Controller
 */
class ContentTypeControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    findContentTypesUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/contenttype`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    findContentTypesUsingGET() {
        return this.findContentTypesUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param contentType contentType
     * @return OK
     */
    addContentTypeUsingPOSTResponse(contentType) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = contentType;
        let req = new HttpRequest('POST', this.rootUrl + `/api/contenttype`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param contentType contentType
     * @return OK
     */
    addContentTypeUsingPOST(contentType) {
        return this.addContentTypeUsingPOSTResponse(contentType).pipe(__map(_r => _r.body));
    }
    /**
     * @param contentTypeId contentTypeId
     * @return OK
     */
    getContentTypeUsingGETResponse(contentTypeId) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/contenttype/${contentTypeId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param contentTypeId contentTypeId
     * @return OK
     */
    getContentTypeUsingGET(contentTypeId) {
        return this.getContentTypeUsingGETResponse(contentTypeId).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ContentTypeControllerService.EditContentTypeUsingPOSTParams` containing the following parameters:
     *
     * - `contentTypeId`: contentTypeId
     *
     * - `contentType`: contentType
     *
     * @return OK
     */
    editContentTypeUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.contentType;
        let req = new HttpRequest('POST', this.rootUrl + `/api/contenttype/${params.contentTypeId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ContentTypeControllerService.EditContentTypeUsingPOSTParams` containing the following parameters:
     *
     * - `contentTypeId`: contentTypeId
     *
     * - `contentType`: contentType
     *
     * @return OK
     */
    editContentTypeUsingPOST(params) {
        return this.editContentTypeUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param contentTypeId contentTypeId
     */
    deleteContentTypeUsingDELETEResponse(contentTypeId) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/contenttype/${contentTypeId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param contentTypeId contentTypeId
     */
    deleteContentTypeUsingDELETE(contentTypeId) {
        return this.deleteContentTypeUsingDELETEResponse(contentTypeId).pipe(__map(_r => _r.body));
    }
}
ContentTypeControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ContentTypeControllerService_Factory() { return new ContentTypeControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ContentTypeControllerService, providedIn: "root" });
export { ContentTypeControllerService };
