/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResetPasswordModel } from '../models/reset-password-model';
import { SendPasswordTokenModel } from '../models/send-password-token-model';
import { CredentialsConfig } from '../models/credentials-config';
import { SignupModel } from '../models/signup-model';
import { Authentication } from '../models/authentication';

/**
 * User Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class UserRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param resetPasswordModel resetPasswordModel
   */
  resetPasswordUsingPOSTResponse(resetPasswordModel: ResetPasswordModel): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = resetPasswordModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/reset-password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param resetPasswordModel resetPasswordModel
   */
  resetPasswordUsingPOST(resetPasswordModel: ResetPasswordModel): __Observable<null> {
    return this.resetPasswordUsingPOSTResponse(resetPasswordModel).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param sendPasswordTokenModel sendPasswordTokenModel
   */
  sendPasswordTokenUsingPOSTResponse(sendPasswordTokenModel: SendPasswordTokenModel): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = sendPasswordTokenModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/send-password-token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param sendPasswordTokenModel sendPasswordTokenModel
   */
  sendPasswordTokenUsingPOST(sendPasswordTokenModel: SendPasswordTokenModel): __Observable<null> {
    return this.sendPasswordTokenUsingPOSTResponse(sendPasswordTokenModel).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param signupModel signupModel
   * @return OK
   */
  signUpUsingPOSTResponse(signupModel: SignupModel): __Observable<__StrictHttpResponse<CredentialsConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = signupModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/sign-up`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CredentialsConfig>;
      })
    );
  }
  /**
   * @param signupModel signupModel
   * @return OK
   */
  signUpUsingPOST(signupModel: SignupModel): __Observable<CredentialsConfig> {
    return this.signUpUsingPOSTResponse(signupModel).pipe(
      __map(_r => _r.body as CredentialsConfig)
    );
  }

  /**
   * @return OK
   */
  getCurrentUserUsingGETResponse(): __Observable<__StrictHttpResponse<Authentication>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-api/user`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Authentication>;
      })
    );
  }
  /**
   * @return OK
   */
  getCurrentUserUsingGET(): __Observable<Authentication> {
    return this.getCurrentUserUsingGETResponse().pipe(
      __map(_r => _r.body as Authentication)
    );
  }
}

module UserRestControllerService {
}

export { UserRestControllerService }
