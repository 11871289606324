import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { CreateSite, DeleteSite, ISitesStateModel, LeaveSite, LoadSites } from './state/sites.state';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmationService } from 'primeng/api';
import { Site } from '@api/models/site';
import { CreateSiteModel } from '@api/models/create-site-model';
import { Account } from '@api/models/account';
import { filter } from 'rxjs/operators';
import { Authentication } from '@api/models/authentication';
import { LoadInvitations } from '../invitations/invitations.state';

@Component({
  selector: 'app-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnDestroy, OnInit {
  @Select(state => state.accounts.selected)
  selectedAccount$: Observable<Account>;
  @Select() sites$: Observable<ISitesStateModel>;
  @Select(state => state.app.user)
  user$: Observable<Authentication>;

  user: Authentication;
  selectedAccount: Account;
  sitesList: Site[];
  sharedSitesList: Site[];
  newSiteModel: CreateSiteModel = new NewSiteModel();
  isDialogVisible = false;
  subscriptions: Subscription = new Subscription();

  constructor(private http: HttpClient, private store: Store, private confirmService: ConfirmationService) {
    this.selectedAccount$.pipe(filter(value => !!value)).subscribe(value => {
      this.selectedAccount = value;
      this.newSiteModel = new NewSiteModel(value.uid);
    });

    this.subscriptions.add(
      this.user$.pipe(filter(value => !!value)).subscribe(value => {
        this.user = value;
      })
    );

    this.subscriptions.add(
      this.sites$.subscribe(value => {
        this.sitesList = value.list;
        this.sharedSitesList = value.shared;
      })
    );
  }

  onConfig(domain) {
    window.open(`${domain}/site-panel/index.html`);
  }

  openModal() {
    this.isDialogVisible = true;
  }

  closeModal() {
    this.isDialogVisible = false;
    this.newSiteModel = new NewSiteModel(this.selectedAccount.uid);
  }

  addNewSite() {
    this.subscriptions.add(this.store.dispatch(new CreateSite(this.newSiteModel)).subscribe(() => this.closeModal()));
  }

  deleteSite(site: Site) {
    this.confirmService.confirm({
      message: 'Are you sure that you want to delete this site?',
      header: `Deleting site: ${site.name}`,
      icon: 'fa fa-question-circle',
      accept: () => {
        this.store.dispatch(new DeleteSite(site));
      },
      reject: () => {}
    });
  }
  ngOnInit() {
    this.store.dispatch(new LoadSites());
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  leaveSite(site: Site) {
    this.store.dispatch(new LeaveSite(site));
  }
}

class NewSiteModel implements CreateSiteModel {
  accountUid;
  site = {
    name: ''
  };

  constructor(accountUid?, name?) {
    if (accountUid) {
      this.accountUid = accountUid;
    }

    if (name) {
      this.site.name = name;
    }
  }
}
