import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Items List Controller
 */
class ItemsListControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    findUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/itemslist`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    findUsingGET() {
        return this.findUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param itemsList itemsList
     * @return OK
     */
    createUsingPOST1Response(itemsList) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = itemsList;
        let req = new HttpRequest('POST', this.rootUrl + `/api/itemslist`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param itemsList itemsList
     * @return OK
     */
    createUsingPOST1(itemsList) {
        return this.createUsingPOST1Response(itemsList).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemsListControllerService.FindListItemsUsingGETParams` containing the following parameters:
     *
     * - `listname`: listname
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    findListItemsUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/itemslist/${params.listname}/items`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemsListControllerService.FindListItemsUsingGETParams` containing the following parameters:
     *
     * - `listname`: listname
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    findListItemsUsingGET(params) {
        return this.findListItemsUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemsListControllerService.AddItemsListEntryUsingPOSTParams` containing the following parameters:
     *
     * - `listname`: listname
     *
     * - `itemsListEntry`: itemsListEntry
     *
     * @return OK
     */
    addItemsListEntryUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.itemsListEntry;
        let req = new HttpRequest('POST', this.rootUrl + `/api/itemslist/${params.listname}/items`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemsListControllerService.AddItemsListEntryUsingPOSTParams` containing the following parameters:
     *
     * - `listname`: listname
     *
     * - `itemsListEntry`: itemsListEntry
     *
     * @return OK
     */
    addItemsListEntryUsingPOST(params) {
        return this.addItemsListEntryUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
}
ItemsListControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ItemsListControllerService_Factory() { return new ItemsListControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ItemsListControllerService, providedIn: "root" });
export { ItemsListControllerService };
