<h3>Set new password</h3>
<form (submit)="submitForm()" class="login-form p-grid">
  <label>Enter new password</label>
  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-user"></span>
      <input
        pInputText
        name="password"
        [(ngModel)]="model.password"
        placeholder="password"
        id="password"
        type="password"
        #password="ngModel"
        [class.ui-state-error]="isError"
      />
      <span *ngIf="isError" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <p></p>

  <div class="p-grid">
    <div class="p-col-4">
      <button pButton (click)="backToLogin()" label="Back" class="ui-button-secondary"></button>
    </div>

    <div class="p-col-8"><button pButton type="submit" label="Set password" class="ui-button-primary"></button></div>
  </div>
</form>
