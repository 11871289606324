import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '@smart-html-panel/ui-library/src/lib/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '@smart-html-panel/ui-library/src/lib/components/components.module';
import { DirectivesModule } from '@smart-html-panel/ui-library/src/lib/directives/directives.module';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

@NgModule({
  imports: [
    CommonModule,
    PrimengModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    DirectivesModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    NgxsFormPluginModule
  ],
  exports: [
    PrimengModule,
    FormsModule,
    ComponentsModule,
    DirectivesModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    NgxsFormPluginModule
  ]
})
export class UiLibraryModule {}
