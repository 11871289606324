/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/button/button";
import * as i2 from "./signup-succes.component";
import * as i3 from "@angular/router";
var styles_SignupSuccesComponent = [];
var RenderType_SignupSuccesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignupSuccesComponent, data: {} });
export { RenderType_SignupSuccesComponent as RenderType_SignupSuccesComponent };
export function View_SignupSuccesComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["User successfully created."])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [["style", "text-align: center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["On provided email address you will now receive mail with activation link."])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "p-grid"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "p-col"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "button", [["class", "ui-button-secondary"], ["label", "Back to login"], ["pButton", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.backToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 4341760, null, 0, i1.ButtonDirective, [i0.ElementRef], { label: [0, "label"] }, null)], function (_ck, _v) { var currVal_0 = "Back to login"; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_SignupSuccesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-signup-succes", [], null, null, null, View_SignupSuccesComponent_0, RenderType_SignupSuccesComponent)), i0.ɵdid(1, 114688, null, 0, i2.SignupSuccesComponent, [i3.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignupSuccesComponentNgFactory = i0.ɵccf("app-signup-succes", i2.SignupSuccesComponent, View_SignupSuccesComponent_Host_0, {}, {}, []);
export { SignupSuccesComponentNgFactory as SignupSuccesComponentNgFactory };
