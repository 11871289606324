import * as tslib_1 from "tslib";
import { Action, State } from '@ngxs/store';
import { UserRestControllerService } from '@api/services';
import { tap } from 'rxjs/operators';
const prefix = '[App]';
export class GetUserInfo {
}
GetUserInfo.type = `${prefix} Get user info`;
let AppState = class AppState {
    constructor(userService) {
        this.userService = userService;
    }
    getUserInfo({ patchState }) {
        return this.userService.getCurrentUserUsingGET().pipe(tap(value => {
            patchState({
                user: value
            });
        }));
    }
};
tslib_1.__decorate([
    Action(GetUserInfo),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AppState.prototype, "getUserInfo", null);
AppState = tslib_1.__decorate([
    State({
        name: 'app',
        defaults: {
            user: null
        }
    }),
    tslib_1.__metadata("design:paramtypes", [UserRestControllerService])
], AppState);
export { AppState };
