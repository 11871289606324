import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup-succes',
  templateUrl: './signup-succes.component.html'
})
export class SignupSuccesComponent implements OnInit {
  constructor(private readonly router: Router) {}

  ngOnInit() {}

  backToLogin() {
    this.router.navigate(['auth/login']);
  }
}
