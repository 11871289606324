import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Internal Item Model Controller
 */
class InternalItemModelControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `InternalItemModelControllerService.IndexItemUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    indexItemUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/headless/item/index/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param params The `InternalItemModelControllerService.IndexItemUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    indexItemUsingPOST(params) {
        return this.indexItemUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `InternalItemModelControllerService.DeleteItemUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     */
    deleteItemUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/headless/item/index/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `InternalItemModelControllerService.DeleteItemUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     */
    deleteItemUsingDELETE(params) {
        return this.deleteItemUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `InternalItemModelControllerService.GetJsonUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getJsonUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/headless/item/preview/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `InternalItemModelControllerService.GetJsonUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getJsonUsingGET(params) {
        return this.getJsonUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
}
InternalItemModelControllerService.ngInjectableDef = i0.defineInjectable({ factory: function InternalItemModelControllerService_Factory() { return new InternalItemModelControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: InternalItemModelControllerService, providedIn: "root" });
export { InternalItemModelControllerService };
