/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Page } from '../models/page';
import { ContentType } from '../models/content-type';

/**
 * Content Type Controller
 */
@Injectable({
  providedIn: 'root',
})
class ContentTypeControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  findContentTypesUsingGETResponse(): __Observable<__StrictHttpResponse<Page>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/contenttype`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Page>;
      })
    );
  }
  /**
   * @return OK
   */
  findContentTypesUsingGET(): __Observable<Page> {
    return this.findContentTypesUsingGETResponse().pipe(
      __map(_r => _r.body as Page)
    );
  }

  /**
   * @param contentType contentType
   * @return OK
   */
  addContentTypeUsingPOSTResponse(contentType: ContentType): __Observable<__StrictHttpResponse<ContentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = contentType;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/contenttype`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContentType>;
      })
    );
  }
  /**
   * @param contentType contentType
   * @return OK
   */
  addContentTypeUsingPOST(contentType: ContentType): __Observable<ContentType> {
    return this.addContentTypeUsingPOSTResponse(contentType).pipe(
      __map(_r => _r.body as ContentType)
    );
  }

  /**
   * @param contentTypeId contentTypeId
   * @return OK
   */
  getContentTypeUsingGETResponse(contentTypeId: number): __Observable<__StrictHttpResponse<ContentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/contenttype/${contentTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContentType>;
      })
    );
  }
  /**
   * @param contentTypeId contentTypeId
   * @return OK
   */
  getContentTypeUsingGET(contentTypeId: number): __Observable<ContentType> {
    return this.getContentTypeUsingGETResponse(contentTypeId).pipe(
      __map(_r => _r.body as ContentType)
    );
  }

  /**
   * @param params The `ContentTypeControllerService.EditContentTypeUsingPOSTParams` containing the following parameters:
   *
   * - `contentTypeId`: contentTypeId
   *
   * - `contentType`: contentType
   *
   * @return OK
   */
  editContentTypeUsingPOSTResponse(params: ContentTypeControllerService.EditContentTypeUsingPOSTParams): __Observable<__StrictHttpResponse<ContentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.contentType;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/contenttype/${params.contentTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ContentType>;
      })
    );
  }
  /**
   * @param params The `ContentTypeControllerService.EditContentTypeUsingPOSTParams` containing the following parameters:
   *
   * - `contentTypeId`: contentTypeId
   *
   * - `contentType`: contentType
   *
   * @return OK
   */
  editContentTypeUsingPOST(params: ContentTypeControllerService.EditContentTypeUsingPOSTParams): __Observable<ContentType> {
    return this.editContentTypeUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as ContentType)
    );
  }

  /**
   * @param contentTypeId contentTypeId
   */
  deleteContentTypeUsingDELETEResponse(contentTypeId: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/contenttype/${contentTypeId}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param contentTypeId contentTypeId
   */
  deleteContentTypeUsingDELETE(contentTypeId: number): __Observable<null> {
    return this.deleteContentTypeUsingDELETEResponse(contentTypeId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module ContentTypeControllerService {

  /**
   * Parameters for editContentTypeUsingPOST
   */
  export interface EditContentTypeUsingPOSTParams {

    /**
     * contentTypeId
     */
    contentTypeId: number;

    /**
     * contentType
     */
    contentType: ContentType;
  }
}

export { ContentTypeControllerService }
