/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resource } from '../models/resource';
import { IterableItemModel } from '../models/iterable-item-model';
import { IterableRenderedItem } from '../models/iterable-rendered-item';

/**
 * Provider Controller
 */
@Injectable({
  providedIn: 'root',
})
class ProviderControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProviderControllerService.ItemsDataUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * @return OK
   */
  itemsDataUsingGETResponse(params: ProviderControllerService.ItemsDataUsingGETParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/headless/items/data/${params.revisionTag}/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * @param params The `ProviderControllerService.ItemsDataUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * @return OK
   */
  itemsDataUsingGET(params: ProviderControllerService.ItemsDataUsingGETParams): __Observable<{}> {
    return this.itemsDataUsingGETResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }

  /**
   * @param params The `ProviderControllerService.ItemsDownloadUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * @return OK
   */
  itemsDownloadUsingGETResponse(params: ProviderControllerService.ItemsDownloadUsingGETParams): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/headless/items/resource/${params.revisionTag}/${params.collectionTypeName}/**`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * @param params The `ProviderControllerService.ItemsDownloadUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * @return OK
   */
  itemsDownloadUsingGET(params: ProviderControllerService.ItemsDownloadUsingGETParams): __Observable<Resource> {
    return this.itemsDownloadUsingGETResponse(params).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * @param params The `ProviderControllerService.ItemsSearchUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  itemsSearchUsingGETResponse(params: ProviderControllerService.ItemsSearchUsingGETParams): __Observable<__StrictHttpResponse<IterableItemModel>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/headless/items/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IterableItemModel>;
      })
    );
  }
  /**
   * @param params The `ProviderControllerService.ItemsSearchUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  itemsSearchUsingGET(params: ProviderControllerService.ItemsSearchUsingGETParams): __Observable<IterableItemModel> {
    return this.itemsSearchUsingGETResponse(params).pipe(
      __map(_r => _r.body as IterableItemModel)
    );
  }

  /**
   * @param params The `ProviderControllerService.RenderitemsDataUsingGETParams` containing the following parameters:
   *
   * - `contextPath`: contextPath
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  renderitemsDataUsingGETResponse(params: ProviderControllerService.RenderitemsDataUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.contextPath != null) __params = __params.set('contextPath', params.contextPath.toString());
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/headless/renderitems/data`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `ProviderControllerService.RenderitemsDataUsingGETParams` containing the following parameters:
   *
   * - `contextPath`: contextPath
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  renderitemsDataUsingGET(params: ProviderControllerService.RenderitemsDataUsingGETParams): __Observable<string> {
    return this.renderitemsDataUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param params The `ProviderControllerService.RenderitemsSearchUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  renderitemsSearchUsingGETResponse(params: ProviderControllerService.RenderitemsSearchUsingGETParams): __Observable<__StrictHttpResponse<IterableRenderedItem>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    if (params.q != null) __params = __params.set('q', params.q.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/headless/renderitems/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IterableRenderedItem>;
      })
    );
  }
  /**
   * @param params The `ProviderControllerService.RenderitemsSearchUsingGETParams` containing the following parameters:
   *
   * - `revisionTag`: revisionTag
   *
   * - `q`: q
   *
   * @return OK
   */
  renderitemsSearchUsingGET(params: ProviderControllerService.RenderitemsSearchUsingGETParams): __Observable<IterableRenderedItem> {
    return this.renderitemsSearchUsingGETResponse(params).pipe(
      __map(_r => _r.body as IterableRenderedItem)
    );
  }
}

module ProviderControllerService {

  /**
   * Parameters for itemsDataUsingGET
   */
  export interface ItemsDataUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;
  }

  /**
   * Parameters for itemsDownloadUsingGET
   */
  export interface ItemsDownloadUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;
  }

  /**
   * Parameters for itemsSearchUsingGET
   */
  export interface ItemsSearchUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * q
     */
    q?: string;
  }

  /**
   * Parameters for renderitemsDataUsingGET
   */
  export interface RenderitemsDataUsingGETParams {

    /**
     * contextPath
     */
    contextPath: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for renderitemsSearchUsingGET
   */
  export interface RenderitemsSearchUsingGETParams {

    /**
     * revisionTag
     */
    revisionTag?: string;

    /**
     * q
     */
    q?: string;
  }
}

export { ProviderControllerService }
