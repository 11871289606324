/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { SiteUser } from '../models/site-user';
import { UserInvitation } from '../models/user-invitation';
import { Site } from '../models/site';

/**
 * Colaboration Controller
 */
@Injectable({
  providedIn: 'root',
})
class ColaborationControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  siteUserListUsingGETResponse(): __Observable<__StrictHttpResponse<Array<SiteUser>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-api/siteuser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<SiteUser>>;
      })
    );
  }
  /**
   * @return OK
   */
  siteUserListUsingGET(): __Observable<Array<SiteUser>> {
    return this.siteUserListUsingGETResponse().pipe(
      __map(_r => _r.body as Array<SiteUser>)
    );
  }

  /**
   * @param siteUser siteUser
   * @return OK
   */
  siteUserSaveUsingPOSTResponse(siteUser: SiteUser): __Observable<__StrictHttpResponse<SiteUser>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = siteUser;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SiteUser>;
      })
    );
  }
  /**
   * @param siteUser siteUser
   * @return OK
   */
  siteUserSaveUsingPOST(siteUser: SiteUser): __Observable<SiteUser> {
    return this.siteUserSaveUsingPOSTResponse(siteUser).pipe(
      __map(_r => _r.body as SiteUser)
    );
  }

  /**
   * @return OK
   */
  invitationsUsingPOSTResponse(): __Observable<__StrictHttpResponse<Array<UserInvitation>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/invitations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<UserInvitation>>;
      })
    );
  }
  /**
   * @return OK
   */
  invitationsUsingPOST(): __Observable<Array<UserInvitation>> {
    return this.invitationsUsingPOSTResponse().pipe(
      __map(_r => _r.body as Array<UserInvitation>)
    );
  }

  /**
   * @param invitationId invitationId
   * @return OK
   */
  acceptInvitationUsingPOSTResponse(invitationId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/invitations/${invitationId}/accept`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param invitationId invitationId
   * @return OK
   */
  acceptInvitationUsingPOST(invitationId: number): __Observable<boolean> {
    return this.acceptInvitationUsingPOSTResponse(invitationId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param invitationId invitationId
   * @return OK
   */
  rejectInvitationUsingPOSTResponse(invitationId: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/invitations/${invitationId}/reject`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param invitationId invitationId
   * @return OK
   */
  rejectInvitationUsingPOST(invitationId: number): __Observable<boolean> {
    return this.rejectInvitationUsingPOSTResponse(invitationId).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @return OK
   */
  getInvitedSitesUsingGETResponse(): __Observable<__StrictHttpResponse<Array<Site>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/user-api/siteuser/shared/sites`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Site>>;
      })
    );
  }
  /**
   * @return OK
   */
  getInvitedSitesUsingGET(): __Observable<Array<Site>> {
    return this.getInvitedSitesUsingGETResponse().pipe(
      __map(_r => _r.body as Array<Site>)
    );
  }

  /**
   * @param siteUid siteUid
   * @return OK
   */
  leaveSiteUsingPOSTResponse(siteUid: string): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/shared/sites/${siteUid}/leave`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param siteUid siteUid
   * @return OK
   */
  leaveSiteUsingPOST(siteUid: string): __Observable<boolean> {
    return this.leaveSiteUsingPOSTResponse(siteUid).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  siteUserDeleteUsingDELETEResponse(id: number): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/user-api/siteuser/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  siteUserDeleteUsingDELETE(id: number): __Observable<number> {
    return this.siteUserDeleteUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  cancelInvitationUsingPOSTResponse(id: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/${id}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  cancelInvitationUsingPOST(id: number): __Observable<boolean> {
    return this.cancelInvitationUsingPOSTResponse(id).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  inviteUsingPOSTResponse(id: number): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/user-api/siteuser/${id}/invite`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  inviteUsingPOST(id: number): __Observable<boolean> {
    return this.inviteUsingPOSTResponse(id).pipe(
      __map(_r => _r.body as boolean)
    );
  }
}

module ColaborationControllerService {
}

export { ColaborationControllerService }
