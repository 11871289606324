import { ResetPasswordModel, SendPasswordTokenModel } from '@api/models';

const prefix = '[Auth]';

export class Login {
  static readonly type = `${prefix} Login`;
}

export class Logout {
  static readonly type = `${prefix} Logout`;
}

export class SendPasswordLinkAction {
  static readonly type = `${prefix} SendPasswordLink`;
  constructor(public payload: SendPasswordTokenModel) {}
}

export class ResetPasswordAction {
  static readonly type = `${prefix} ResetPasswordAction`;
  constructor(public payload: ResetPasswordModel) {}
}
