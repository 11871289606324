/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Content } from '../models/content';

/**
 * Web Content Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class WebContentRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WebContentRestControllerService.FindOneUsingGET1Params` containing the following parameters:
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findOneUsingGET1Response(params: WebContentRestControllerService.FindOneUsingGET1Params): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * @param params The `WebContentRestControllerService.FindOneUsingGET1Params` containing the following parameters:
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findOneUsingGET1(params: WebContentRestControllerService.FindOneUsingGET1Params): __Observable<Content> {
    return this.findOneUsingGET1Response(params).pipe(
      __map(_r => _r.body as Content)
    );
  }

  /**
   * @param params The `WebContentRestControllerService.SaveUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  saveUsingPOSTResponse(params: WebContentRestControllerService.SaveUsingPOSTParams): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.item;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * @param params The `WebContentRestControllerService.SaveUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  saveUsingPOST(params: WebContentRestControllerService.SaveUsingPOSTParams): __Observable<Content> {
    return this.saveUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Content)
    );
  }

  /**
   * @param params The `WebContentRestControllerService.GetResourcesUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getResourcesUsingGETResponse(params: WebContentRestControllerService.GetResourcesUsingGETParams): __Observable<__StrictHttpResponse<Array<Content>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Content>>;
      })
    );
  }
  /**
   * @param params The `WebContentRestControllerService.GetResourcesUsingGETParams` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getResourcesUsingGET(params: WebContentRestControllerService.GetResourcesUsingGETParams): __Observable<Array<Content>> {
    return this.getResourcesUsingGETResponse(params).pipe(
      __map(_r => _r.body as Array<Content>)
    );
  }

  /**
   * @param params The `WebContentRestControllerService.SavePreferencesUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  savePreferencesUsingPOSTResponse(params: WebContentRestControllerService.SavePreferencesUsingPOSTParams): __Observable<__StrictHttpResponse<Content>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.item;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/content/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Content>;
      })
    );
  }
  /**
   * @param params The `WebContentRestControllerService.SavePreferencesUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  savePreferencesUsingPOST(params: WebContentRestControllerService.SavePreferencesUsingPOSTParams): __Observable<Content> {
    return this.savePreferencesUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Content)
    );
  }
}

module WebContentRestControllerService {

  /**
   * Parameters for findOneUsingGET1
   */
  export interface FindOneUsingGET1Params {

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for saveUsingPOST
   */
  export interface SaveUsingPOSTParams {

    /**
     * item
     */
    item: Content;

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for getResourcesUsingGET
   */
  export interface GetResourcesUsingGETParams {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for savePreferencesUsingPOST
   */
  export interface SavePreferencesUsingPOSTParams {

    /**
     * item
     */
    item: Content;

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }
}

export { WebContentRestControllerService }
