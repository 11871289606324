import * as tslib_1 from "tslib";
import { Action, Selector, State } from '@ngxs/store';
import { AccountRestControllerService } from '@api/services/account-rest-controller.service';
export class LoadAccounts {
}
LoadAccounts.type = '[Accounts] Load accounts';
export class LoadAccountsSuccess {
    constructor(payload) {
        this.payload = payload;
    }
}
LoadAccountsSuccess.type = '[Accounts] Load accounts success';
export class SelectAccount {
    constructor(payload) {
        this.payload = payload;
    }
}
SelectAccount.type = '[Accounts] Select account';
let AccountsState = class AccountsState {
    constructor(accountRestControllerService) {
        this.accountRestControllerService = accountRestControllerService;
    }
    static accounts(state) {
        return state.list;
    }
    static selectedAccount(state) {
        return state.selected || '';
    }
    loadAccounts(sc) {
        sc.patchState({ loading: true });
        this.accountRestControllerService.findAllUsingGET().subscribe(value => {
            return sc.dispatch(new LoadAccountsSuccess(value));
        });
    }
    loadAccountsSuccess(sc, { payload }) {
        sc.setState(Object.assign({}, sc.getState(), { list: payload, loading: false }));
        return sc.dispatch(new SelectAccount(payload[0]));
    }
    selectAccount(sc, { payload }) {
        sc.setState(Object.assign({}, sc.getState(), { selected: payload }));
    }
};
tslib_1.__decorate([
    Action(LoadAccounts),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AccountsState.prototype, "loadAccounts", null);
tslib_1.__decorate([
    Action(LoadAccountsSuccess),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, LoadAccountsSuccess]),
    tslib_1.__metadata("design:returntype", void 0)
], AccountsState.prototype, "loadAccountsSuccess", null);
tslib_1.__decorate([
    Action(SelectAccount),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object, SelectAccount]),
    tslib_1.__metadata("design:returntype", void 0)
], AccountsState.prototype, "selectAccount", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AccountsState, "accounts", null);
tslib_1.__decorate([
    Selector(),
    tslib_1.__metadata("design:type", Function),
    tslib_1.__metadata("design:paramtypes", [Object]),
    tslib_1.__metadata("design:returntype", void 0)
], AccountsState, "selectedAccount", null);
AccountsState = tslib_1.__decorate([
    State({
        name: 'accounts',
        defaults: {
            list: [],
            selected: null,
            loading: false,
            error: null
        }
    }),
    tslib_1.__metadata("design:paramtypes", [AccountRestControllerService])
], AccountsState);
export { AccountsState };
