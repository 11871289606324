import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Account Rest Controller
 */
class AccountRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `AccountRestControllerService.FindAllUsingGET2Params` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `siteUid`:
     *
     * @return OK
     */
    findAllUsingGET2Response(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.siteUid != null)
            __params = __params.set('siteUid', params.siteUid.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/admin-api/account`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AccountRestControllerService.FindAllUsingGET2Params` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `siteUid`:
     *
     * @return OK
     */
    findAllUsingGET2(params) {
        return this.findAllUsingGET2Response(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `AccountRestControllerService.CreateUsingPOSTParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `siteUid`:
     *
     * @return OK
     */
    createUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.siteUid != null)
            __params = __params.set('siteUid', params.siteUid.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/admin-api/account`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AccountRestControllerService.CreateUsingPOSTParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `siteUid`:
     *
     * @return OK
     */
    createUsingPOST(params) {
        return this.createUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    findAllUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/admin-api/account/all`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    findAllUsingGET() {
        return this.findAllUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param accountUid accountUid
     * @return OK
     */
    findAllUsingGET1Response(accountUid) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/admin-api/account/${accountUid}/site`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param accountUid accountUid
     * @return OK
     */
    findAllUsingGET1(accountUid) {
        return this.findAllUsingGET1Response(accountUid).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `AccountRestControllerService.FindOneUsingGETParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    findOneUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.siteUid != null)
            __params = __params.set('siteUid', params.siteUid.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/admin-api/account/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AccountRestControllerService.FindOneUsingGETParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    findOneUsingGET(params) {
        return this.findOneUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `AccountRestControllerService.UpdateUsingPOSTParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    updateUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.siteUid != null)
            __params = __params.set('siteUid', params.siteUid.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/admin-api/account/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AccountRestControllerService.UpdateUsingPOSTParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    updateUsingPOST(params) {
        return this.updateUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `AccountRestControllerService.DeleteAccountUsingDELETEParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    deleteAccountUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.siteUid != null)
            __params = __params.set('siteUid', params.siteUid.toString());
        let req = new HttpRequest('DELETE', this.rootUrl + `/admin-api/account/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AccountRestControllerService.DeleteAccountUsingDELETEParams` containing the following parameters:
     *
     * - `vars`: vars
     *
     * - `id`: id
     *
     * - `siteUid`:
     *
     * @return OK
     */
    deleteAccountUsingDELETE(params) {
        return this.deleteAccountUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
}
AccountRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function AccountRestControllerService_Factory() { return new AccountRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AccountRestControllerService, providedIn: "root" });
export { AccountRestControllerService };
