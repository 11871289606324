import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Admin Site Rest Controller
 */
class AdminSiteRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `AdminSiteRestControllerService.CreateSiteUsingPOSTParams` containing the following parameters:
     *
     * - `site`: site
     *
     * - `accountUid`: accountUid
     *
     * @return OK
     */
    createSiteUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.site;
        if (params.accountUid != null)
            __params = __params.set('accountUid', params.accountUid.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/admin-api/site`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `AdminSiteRestControllerService.CreateSiteUsingPOSTParams` containing the following parameters:
     *
     * - `site`: site
     *
     * - `accountUid`: accountUid
     *
     * @return OK
     */
    createSiteUsingPOST(params) {
        return this.createSiteUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param createSiteModel createSiteModel
     * @return OK
     */
    addSiteUsingPOSTResponse(createSiteModel) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = createSiteModel;
        let req = new HttpRequest('POST', this.rootUrl + `/admin-api/site/add`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param createSiteModel createSiteModel
     * @return OK
     */
    addSiteUsingPOST(createSiteModel) {
        return this.addSiteUsingPOSTResponse(createSiteModel).pipe(__map(_r => _r.body));
    }
    /**
     * @param siteUid siteUid
     */
    deleteSiteUsingDELETEResponse(siteUid) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/admin-api/site/${siteUid}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param siteUid siteUid
     */
    deleteSiteUsingDELETE(siteUid) {
        return this.deleteSiteUsingDELETEResponse(siteUid).pipe(__map(_r => _r.body));
    }
}
AdminSiteRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function AdminSiteRestControllerService_Factory() { return new AdminSiteRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: AdminSiteRestControllerService, providedIn: "root" });
export { AdminSiteRestControllerService };
