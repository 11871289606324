import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SitesComponent } from './sites.component';
import { NgxsModule } from '@ngxs/store';
import { SitesState } from './state/sites.state';
import { UiLibraryModule } from '@smart-html/ui-library';

@NgModule({
  imports: [
    CommonModule,
    // SitesRoutingModule,
    UiLibraryModule,
    NgxsModule.forFeature([SitesState])
  ],
  declarations: [SitesComponent],
  exports: [SitesComponent]
})
export class SitesModule {}
