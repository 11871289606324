import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWrapperDirective } from './page-wrapper.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [PageWrapperDirective],
  exports: [PageWrapperDirective]
})
export class DirectivesModule {}
