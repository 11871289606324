import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { CreateSite, DeleteSite, LeaveSite, LoadSites } from './state/sites.state';
import { Subscription } from 'rxjs/Subscription';
import { ConfirmationService } from 'primeng/api';
import { filter } from 'rxjs/operators';
const ɵ0 = state => state.accounts.selected, ɵ1 = state => state.app.user;
export class SitesComponent {
    constructor(http, store, confirmService) {
        this.http = http;
        this.store = store;
        this.confirmService = confirmService;
        this.newSiteModel = new NewSiteModel();
        this.isDialogVisible = false;
        this.subscriptions = new Subscription();
        this.selectedAccount$.pipe(filter(value => !!value)).subscribe(value => {
            this.selectedAccount = value;
            this.newSiteModel = new NewSiteModel(value.uid);
        });
        this.subscriptions.add(this.user$.pipe(filter(value => !!value)).subscribe(value => {
            this.user = value;
        }));
        this.subscriptions.add(this.sites$.subscribe(value => {
            this.sitesList = value.list;
            this.sharedSitesList = value.shared;
        }));
    }
    onConfig(domain) {
        window.open(`${domain}/site-panel/index.html`);
    }
    openModal() {
        this.isDialogVisible = true;
    }
    closeModal() {
        this.isDialogVisible = false;
        this.newSiteModel = new NewSiteModel(this.selectedAccount.uid);
    }
    addNewSite() {
        this.subscriptions.add(this.store.dispatch(new CreateSite(this.newSiteModel)).subscribe(() => this.closeModal()));
    }
    deleteSite(site) {
        this.confirmService.confirm({
            message: 'Are you sure that you want to delete this site?',
            header: `Deleting site: ${site.name}`,
            icon: 'fa fa-question-circle',
            accept: () => {
                this.store.dispatch(new DeleteSite(site));
            },
            reject: () => { }
        });
    }
    ngOnInit() {
        this.store.dispatch(new LoadSites());
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
    leaveSite(site) {
        this.store.dispatch(new LeaveSite(site));
    }
}
tslib_1.__decorate([
    Select(ɵ0),
    tslib_1.__metadata("design:type", Observable)
], SitesComponent.prototype, "selectedAccount$", void 0);
tslib_1.__decorate([
    Select(),
    tslib_1.__metadata("design:type", Observable)
], SitesComponent.prototype, "sites$", void 0);
tslib_1.__decorate([
    Select(ɵ1),
    tslib_1.__metadata("design:type", Observable)
], SitesComponent.prototype, "user$", void 0);
class NewSiteModel {
    constructor(accountUid, name) {
        this.site = {
            name: ''
        };
        if (accountUid) {
            this.accountUid = accountUid;
        }
        if (name) {
            this.site.name = name;
        }
    }
}
export { ɵ0, ɵ1 };
