/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/primeng/components/button/button.ngfactory";
import * as i2 from "primeng/components/button/button";
import * as i3 from "./button-with-label.component";
var styles_ButtonWithLabelComponent = ["[_nghost-%COMP%] {\n      cursor: pointer;\n    }"];
var RenderType_ButtonWithLabelComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ButtonWithLabelComponent, data: {} });
export { RenderType_ButtonWithLabelComponent as RenderType_ButtonWithLabelComponent };
export function View_ButtonWithLabelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "p-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.click($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_Button_0, i1.RenderType_Button)), i0.ɵdid(2, 49152, null, 0, i2.Button, [], { icon: [0, "icon"], styleClass: [1, "styleClass"] }, { onClick: "onClick" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "ui-button-with-label__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.icon; var currVal_2 = _co.styleClass; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "ui-button-with-label ", _co.additionalClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.label; _ck(_v, 4, 0, currVal_3); }); }
export function View_ButtonWithLabelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sh-button-with-label", [], null, null, null, View_ButtonWithLabelComponent_0, RenderType_ButtonWithLabelComponent)), i0.ɵdid(1, 49152, null, 0, i3.ButtonWithLabelComponent, [], null, null)], null, null); }
var ButtonWithLabelComponentNgFactory = i0.ɵccf("sh-button-with-label", i3.ButtonWithLabelComponent, View_ButtonWithLabelComponent_Host_0, { icon: "icon", styleClass: "styleClass", label: "label", additionalClass: "additionalClass" }, { onClick: "onClick" }, []);
export { ButtonWithLabelComponentNgFactory as ButtonWithLabelComponentNgFactory };
