<form
  (submit)="submitForm()"
  [formGroup]="loginForm"
  ngxsForm="auth.loginForm"
  ngxsFormClearOnDestroy="true"
  class="login-form p-grid"
>
  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-user"></span>
      <input
        pInputText
        formControlName="username"
        placeholder="Username"
        id="username"
        type="text"
        [class.ui-state-error]="isError"
      />
      <span *ngIf="isError" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="ui-input-wrapper p-col">
    <div class="ui-input-wrapper__inner">
      <span class="sh sh-password"></span>
      <input
        pInputText
        formControlName="password"
        id="password"
        placeholder="Password"
        type="password"
        [class.ui-state-error]="isError"
      />
      <span *ngIf="isError" class="sh sh-validation sh-error"></span>
    </div>
  </div>

  <div class="p-col">
    <button pButton [disabled]="!loginForm.valid" type="submit" label="Login" class="login-form--item"></button>
  </div>

  <a (click)="startResetPassword()">Forgot password?</a>
</form>
