import { Action, State, StateContext } from '@ngxs/store';
import { UserRestControllerService } from '@api/services';
import { tap } from 'rxjs/operators';
import { Authentication } from '@api/models/authentication';

export interface IAppStateModel {
  user: Authentication;
}

const prefix = '[App]';

export class GetUserInfo {
  static readonly type = `${prefix} Get user info`;
}

@State<IAppStateModel>({
  name: 'app',
  defaults: {
    user: null
  }
})
export class AppState {
  constructor(private userService: UserRestControllerService) {}

  @Action(GetUserInfo)
  getUserInfo({ patchState }: StateContext<IAppStateModel>) {
    return this.userService.getCurrentUserUsingGET().pipe(
      tap(value => {
        patchState({
          user: value
        });
      })
    );
  }
}
