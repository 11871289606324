import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Renderer Rest Controller
 */
class RendererRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `RendererRestControllerService.ProcessUsingGETParams` containing the following parameters:
     *
     * - `personaName`: personaName
     *
     * - `persona`: persona
     *
     * - `collectionId`: collectionId
     *
     * @return OK
     */
    processUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.personaName != null)
            __params = __params.set('personaName', params.personaName.toString());
        if (params.persona != null)
            __params = __params.set('persona', params.persona.toString());
        if (params.collectionId != null)
            __params = __params.set('collectionId', params.collectionId.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `RendererRestControllerService.ProcessUsingGETParams` containing the following parameters:
     *
     * - `personaName`: personaName
     *
     * - `persona`: persona
     *
     * - `collectionId`: collectionId
     *
     * @return OK
     */
    processUsingGET(params) {
        return this.processUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `RendererRestControllerService.GetFragmentUsingGETParams` containing the following parameters:
     *
     * - `removeHostNode`: removeHostNode
     *
     * - `fragmentSpec`: fragmentSpec
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    getFragmentUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.removeHostNode != null)
            __params = __params.set('removeHostNode', params.removeHostNode.toString());
        if (params.fragmentSpec != null)
            __params = __params.set('fragmentSpec', params.fragmentSpec.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/render/fragment`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `RendererRestControllerService.GetFragmentUsingGETParams` containing the following parameters:
     *
     * - `removeHostNode`: removeHostNode
     *
     * - `fragmentSpec`: fragmentSpec
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    getFragmentUsingGET(params) {
        return this.getFragmentUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    refreshRenderItemsUsingPOSTResponse(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (revisionTag != null)
            __params = __params.set('revisionTag', revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/render/refresh`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    refreshRenderItemsUsingPOST(revisionTag) {
        return this.refreshRenderItemsUsingPOSTResponse(revisionTag).pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    getRenderItemsUsingGETResponse(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (revisionTag != null)
            __params = __params.set('revisionTag', revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/render/renderitems`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    getRenderItemsUsingGET(revisionTag) {
        return this.getRenderItemsUsingGETResponse(revisionTag).pipe(__map(_r => _r.body));
    }
    /**
     * @param renderitemId renderitemId
     */
    deleteRenderItemUsingDELETEResponse(renderitemId) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/render/renderitems/${renderitemId}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param renderitemId renderitemId
     */
    deleteRenderItemUsingDELETE(renderitemId) {
        return this.deleteRenderItemUsingDELETEResponse(renderitemId).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `RendererRestControllerService.RenderItemUsingGETParams` containing the following parameters:
     *
     * - `resourcePath`: resourcePath
     *
     * - `revisionTag`: revisionTag
     *
     * - `personaName`: personaName
     *
     * - `persona`: persona
     *
     * @return OK
     */
    renderItemUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.resourcePath != null)
            __params = __params.set('resourcePath', params.resourcePath.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.personaName != null)
            __params = __params.set('personaName', params.personaName.toString());
        if (params.persona != null)
            __params = __params.set('persona', params.persona.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/render/resource`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `RendererRestControllerService.RenderItemUsingGETParams` containing the following parameters:
     *
     * - `resourcePath`: resourcePath
     *
     * - `revisionTag`: revisionTag
     *
     * - `personaName`: personaName
     *
     * - `persona`: persona
     *
     * @return OK
     */
    renderItemUsingGET(params) {
        return this.renderItemUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
}
RendererRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function RendererRestControllerService_Factory() { return new RendererRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: RendererRestControllerService, providedIn: "root" });
export { RendererRestControllerService };
