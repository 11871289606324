/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Web Controller
 */
@Injectable({
  providedIn: 'root',
})
class WebControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WebControllerService.LogsUsingGETParams` containing the following parameters:
   *
   * - `uid`: uid
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  logsUsingGETResponse(params: WebControllerService.LogsUsingGETParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uid != null) __params = __params.set('uid', params.uid.toString());
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `WebControllerService.LogsUsingGETParams` containing the following parameters:
   *
   * - `uid`: uid
   *
   * - `size`: size
   *
   * - `page`: page
   *
   * @return OK
   */
  logsUsingGET(params: WebControllerService.LogsUsingGETParams): __Observable<string> {
    return this.logsUsingGETResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return OK
   */
  signinUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/signin.html`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return OK
   */
  signinUsingGET(): __Observable<string> {
    return this.signinUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return OK
   */
  loginUsingGETResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/weblogin`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return OK
   */
  loginUsingGET(): __Observable<string> {
    return this.loginUsingGETResponse().pipe(
      __map(_r => _r.body as string)
    );
  }
}

module WebControllerService {

  /**
   * Parameters for logsUsingGET
   */
  export interface LogsUsingGETParams {

    /**
     * uid
     */
    uid?: string;

    /**
     * size
     */
    size?: number;

    /**
     * page
     */
    page?: number;
  }
}

export { WebControllerService }
