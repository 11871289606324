import { Action, Select, State, StateContext, Store } from '@ngxs/store';
import { AccountsState } from '../../../store/accounts.state';
import { Observable } from 'rxjs/Observable';
import { filter, map, take, tap } from 'rxjs/operators';
import { Site } from '@api/models/site';
import { CreateSiteModel } from '@api/models/create-site-model';
import { AccountRestControllerService } from '@api/services/account-rest-controller.service';
import { Account } from '@api/models/account';
import { AdminSiteRestControllerService } from '@api/services/admin-site-rest-controller.service';
import { ColaborationControllerService } from '@api/services/colaboration-controller.service';
import { LoadInvitations } from '../../invitations/invitations.state';

export interface ISitesStateModel {
  list: Site[];
  shared: Site[];

  loading: boolean;
  error: any;
}

export class LoadSites {
  static readonly type = '[Sites] Load sites';
}

export class LoadSharedSites {
  static readonly type = '[Sites] Load sharedsites';
}

export class LoadSitesSuccess {
  static readonly type = '[Sites] Load sites success';
  constructor(public payload: Site[]) {}
}

export class LoadSitesFailure {
  static readonly type = '[Sites] Load failed';
  constructor(public payload: any) {}
}

export class CreateSite {
  static readonly type = '[Sites] Create site';
  constructor(public payload: CreateSiteModel) {}
}

export class DeleteSite {
  static readonly type = '[Sites] Delete site';
  constructor(public payload: Site) {}
}

export class LeaveSite {
  static readonly type = '[Sites] Leave site';
  constructor(public payload: Site) {}
}

@State<ISitesStateModel>({
  name: 'sites',
  defaults: {
    list: [],
    shared: [],
    loading: false,
    error: null
  }
})
export class SitesState {
  @Select(AccountsState.selectedAccount) selectedAccount$: Observable<Account>;

  selectedAccount: Account;

  constructor(
    private adminSiteService: AdminSiteRestControllerService,
    private accountRestControllerService: AccountRestControllerService,
    private colaborationService: ColaborationControllerService,
    private store: Store
  ) {
    this.selectedAccount$
      .pipe(
        filter(value => !!value),
        take(1)
      )
      .subscribe(value => {
        this.selectedAccount = value;
        this.store.dispatch(new LoadSites());
        this.store.dispatch(new LoadSharedSites());
      });
  }

  @Action(LoadSites)
  loadSites(sc: StateContext<ISitesStateModel>) {
    sc.patchState({ loading: true });

    return this.accountRestControllerService.findAllUsingGET1(this.selectedAccount.uid).pipe(
      map(value => {
        sc.dispatch(new LoadSitesSuccess(value));
      })
    );
  }

  @Action(LoadSharedSites)
  loadSharedSites(ctx: StateContext<ISitesStateModel>) {
    ctx.patchState({ loading: true });

    return this.colaborationService.getInvitedSitesUsingGET().pipe(
      tap(value => {
        ctx.setState({
          ...ctx.getState(),
          shared: value
        });
      })
    );
  }

  @Action(LoadSitesSuccess)
  loadSiteSuccess(sc: StateContext<ISitesStateModel>, { payload }) {
    return sc.setState({
      ...sc.getState(),
      list: payload,
      loading: false
    });
  }

  @Action(CreateSite)
  createSIte(sc: StateContext<ISitesStateModel>, { payload }) {
    return this.adminSiteService.createSiteUsingPOST(payload).pipe(
      map(value => {
        const state = sc.getState();
        sc.setState({
          ...state,
          list: [...state.list, value]
        });
      })
    );
  }

  @Action(DeleteSite)
  deleteSite(sc: StateContext<ISitesStateModel>, { payload }) {
    return this.adminSiteService
      .deleteSiteUsingDELETE(payload.uid)
      .toPromise()
      .then(value => {
        const state = sc.getState();
        state.list = state.list.filter(val => val.uid !== payload.uid);
        sc.setState({
          ...state
        });
      });
  }

  @Action(LeaveSite)
  leaveSite(sc: StateContext<ISitesStateModel>, { payload }) {
    return this.colaborationService.leaveSiteUsingPOST(payload.uid).pipe(
      map(value => {
        sc.dispatch(new LoadSharedSites());
      })
    );
  }
}
