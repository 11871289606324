
    <div class="header-profile">
      <span class="header-profile__name">{{account.name}}</span>
      <p-button 
        icon="sh sh-log-out" 
        styleClass="ui-button-transparent ui-button-transparent--large"
        (onClick)="onLogout()"
      ></p-button>
    </div>

  