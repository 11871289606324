import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Colaboration Controller
 */
class ColaborationControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @return OK
     */
    siteUserListUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/user-api/siteuser`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    siteUserListUsingGET() {
        return this.siteUserListUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param siteUser siteUser
     * @return OK
     */
    siteUserSaveUsingPOSTResponse(siteUser) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = siteUser;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param siteUser siteUser
     * @return OK
     */
    siteUserSaveUsingPOST(siteUser) {
        return this.siteUserSaveUsingPOSTResponse(siteUser).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    invitationsUsingPOSTResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/invitations`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    invitationsUsingPOST() {
        return this.invitationsUsingPOSTResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param invitationId invitationId
     * @return OK
     */
    acceptInvitationUsingPOSTResponse(invitationId) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/invitations/${invitationId}/accept`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param invitationId invitationId
     * @return OK
     */
    acceptInvitationUsingPOST(invitationId) {
        return this.acceptInvitationUsingPOSTResponse(invitationId).pipe(__map(_r => _r.body));
    }
    /**
     * @param invitationId invitationId
     * @return OK
     */
    rejectInvitationUsingPOSTResponse(invitationId) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/invitations/${invitationId}/reject`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param invitationId invitationId
     * @return OK
     */
    rejectInvitationUsingPOST(invitationId) {
        return this.rejectInvitationUsingPOSTResponse(invitationId).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    getInvitedSitesUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/user-api/siteuser/shared/sites`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    getInvitedSitesUsingGET() {
        return this.getInvitedSitesUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param siteUid siteUid
     * @return OK
     */
    leaveSiteUsingPOSTResponse(siteUid) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/shared/sites/${siteUid}/leave`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param siteUid siteUid
     * @return OK
     */
    leaveSiteUsingPOST(siteUid) {
        return this.leaveSiteUsingPOSTResponse(siteUid).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    siteUserDeleteUsingDELETEResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/user-api/siteuser/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: parseFloat(_r.body) });
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    siteUserDeleteUsingDELETE(id) {
        return this.siteUserDeleteUsingDELETEResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    cancelInvitationUsingPOSTResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/${id}/cancel`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    cancelInvitationUsingPOST(id) {
        return this.cancelInvitationUsingPOSTResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    inviteUsingPOSTResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('POST', this.rootUrl + `/user-api/siteuser/${id}/invite`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r.clone({ body: _r.body === 'true' });
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    inviteUsingPOST(id) {
        return this.inviteUsingPOSTResponse(id).pipe(__map(_r => _r.body));
    }
}
ColaborationControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ColaborationControllerService_Factory() { return new ColaborationControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ColaborationControllerService, providedIn: "root" });
export { ColaborationControllerService };
