import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivateRoutingModule } from './private-routing.module';
import { PrivateComponent } from './private/private.component';
import { FooterComponent } from '../footer/footer.component';
import { NavigationComponent } from '../navigation/navigation.component';
import { SitesModule } from './sites/sites.module';
import { TestComponent } from './test/test.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { UiLibraryModule } from '@smart-html/ui-library';

@NgModule({
  imports: [CommonModule, PrivateRoutingModule, SitesModule, UiLibraryModule],
  declarations: [
    PrivateComponent,
    FooterComponent,
    NavigationComponent,
    TestComponent,
    DashboardComponent,
    ProfileComponent
  ]
})
export class PrivateModule {}
