/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { WebPage } from '../models/web-page';

/**
 * Web Page Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class WebPageRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `WebPageRestControllerService.FindOneUsingGET2Params` containing the following parameters:
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findOneUsingGET2Response(params: WebPageRestControllerService.FindOneUsingGET2Params): __Observable<__StrictHttpResponse<WebPage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/webpage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WebPage>;
      })
    );
  }
  /**
   * @param params The `WebPageRestControllerService.FindOneUsingGET2Params` containing the following parameters:
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  findOneUsingGET2(params: WebPageRestControllerService.FindOneUsingGET2Params): __Observable<WebPage> {
    return this.findOneUsingGET2Response(params).pipe(
      __map(_r => _r.body as WebPage)
    );
  }

  /**
   * @param params The `WebPageRestControllerService.SaveUsingPOST1Params` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  saveUsingPOST1Response(params: WebPageRestControllerService.SaveUsingPOST1Params): __Observable<__StrictHttpResponse<WebPage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.item;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/webpage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WebPage>;
      })
    );
  }
  /**
   * @param params The `WebPageRestControllerService.SaveUsingPOST1Params` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  saveUsingPOST1(params: WebPageRestControllerService.SaveUsingPOST1Params): __Observable<WebPage> {
    return this.saveUsingPOST1Response(params).pipe(
      __map(_r => _r.body as WebPage)
    );
  }

  /**
   * @param params The `WebPageRestControllerService.GetResourcesUsingGET1Params` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getResourcesUsingGET1Response(params: WebPageRestControllerService.GetResourcesUsingGET1Params): __Observable<__StrictHttpResponse<Array<WebPage>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/webpage/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<WebPage>>;
      })
    );
  }
  /**
   * @param params The `WebPageRestControllerService.GetResourcesUsingGET1Params` containing the following parameters:
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  getResourcesUsingGET1(params: WebPageRestControllerService.GetResourcesUsingGET1Params): __Observable<Array<WebPage>> {
    return this.getResourcesUsingGET1Response(params).pipe(
      __map(_r => _r.body as Array<WebPage>)
    );
  }

  /**
   * @param params The `WebPageRestControllerService.SavePreferencesUsingPOST1Params` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  savePreferencesUsingPOST1Response(params: WebPageRestControllerService.SavePreferencesUsingPOST1Params): __Observable<__StrictHttpResponse<WebPage>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.item;
    if (params.filePath != null) __params = __params.set('filePath', params.filePath.toString());

    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection/${params.collectionTypeName}/items/webpage/preferences`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<WebPage>;
      })
    );
  }
  /**
   * @param params The `WebPageRestControllerService.SavePreferencesUsingPOST1Params` containing the following parameters:
   *
   * - `item`: item
   *
   * - `filePath`: filePath
   *
   * - `collectionTypeName`: collectionTypeName
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  savePreferencesUsingPOST1(params: WebPageRestControllerService.SavePreferencesUsingPOST1Params): __Observable<WebPage> {
    return this.savePreferencesUsingPOST1Response(params).pipe(
      __map(_r => _r.body as WebPage)
    );
  }
}

module WebPageRestControllerService {

  /**
   * Parameters for findOneUsingGET2
   */
  export interface FindOneUsingGET2Params {

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for saveUsingPOST1
   */
  export interface SaveUsingPOST1Params {

    /**
     * item
     */
    item: WebPage;

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for getResourcesUsingGET1
   */
  export interface GetResourcesUsingGET1Params {

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for savePreferencesUsingPOST1
   */
  export interface SavePreferencesUsingPOST1Params {

    /**
     * item
     */
    item: WebPage;

    /**
     * filePath
     */
    filePath: string;

    /**
     * collectionTypeName
     */
    collectionTypeName: string;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }
}

export { WebPageRestControllerService }
