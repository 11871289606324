import { Component } from '@angular/core';
import { AccountsState, LoadAccounts } from '../../store/accounts.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/Observable';
import { Account } from '@api/models/account';
import { MenuItem } from 'primeng/api';
import { Logout } from '../../public/authentication/state/auth.actions';
import { GetUserInfo } from '../../store/app.state';

@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent {
  @Select(AccountsState.accounts) accounts$: Observable<Account[]>;
  @Select(AccountsState.selectedAccount) selectedAccount$: Observable<Account>;

  menu: MenuItem[] = [
    {
      label: 'Sites',
      routerLink: '/private/sites'
    },
    {
      label: 'Invitaions',
      routerLink: '/private/invitations'
    }
  ];

  constructor(private store: Store) {
    this.store.dispatch(new LoadAccounts());
    this.store.dispatch(new GetUserInfo());
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
