import { Action, State, StateContext } from '@ngxs/store';
import { getFormModelDefaults, IFormModel } from '@smart-html-panel/types/types';
import { prop, required } from '@rxweb/reactive-form-validators';
import { Login, Logout, ResetPasswordAction, SendPasswordLinkAction } from './auth.actions';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Navigate } from '@ngxs/router-plugin';
import { UserRestControllerService } from '@api/services/user-rest-controller.service';

export class LoginModel {
  @required()
  @prop()
  username = '';

  @required()
  @prop()
  password = '';
}

interface ILoginResponse {
  token: string;
  expirationDate: string;
}

export class AuthStateModel {
  public token: string;
  public expirationDate: string;
  public loginForm: IFormModel<LoginModel>;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    expirationDate: null,
    loginForm: getFormModelDefaults()
  }
})
export class AuthState {
  constructor(private http: HttpClient, private userControllerService: UserRestControllerService) {}

  @Action(Login)
  login({ patchState, getState, dispatch }: StateContext<AuthStateModel>) {
    const loginData = getState().loginForm.model;
    const formData = new FormData();
    Object.keys(loginData).forEach(key => {
      formData.append(key, loginData[key]);
    });

    return this.http.post<ILoginResponse>(`/login`, formData).pipe(
      tap(({ token, expirationDate }) => {
        patchState({
          token,
          expirationDate
        });
        dispatch(new Navigate(['private']));
      })
    );
  }

  @Action(SendPasswordLinkAction)
  sendPasswordLink(
    { patchState, getState, dispatch }: StateContext<AuthStateModel>,
    { payload }: SendPasswordLinkAction
  ) {
    return this.userControllerService
      .sendPasswordTokenUsingPOST(payload)

      .pipe(
        tap(() => {
          dispatch(new Navigate(['auth/send-password-token-success']));
        })
      );
  }

  @Action(ResetPasswordAction)
  resetPassword({ patchState, getState, dispatch }: StateContext<AuthStateModel>, { payload }: ResetPasswordAction) {
    return this.userControllerService
      .resetPasswordUsingPOST(payload)

      .pipe(
        tap(() => {
          dispatch(new Navigate(['auth/reset-password-success']));
        })
      );
  }

  @Action(Logout)
  logout({ patchState, dispatch }: StateContext<AuthStateModel>) {
    patchState({
      token: null,
      expirationDate: null
    });
    dispatch(new Navigate(['auth']));
  }
}
