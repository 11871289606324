/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/primeng/components/common/shared.ngfactory";
import * as i2 from "primeng/components/common/shared";
import * as i3 from "../../../../../../node_modules/primeng/components/tabmenu/tabmenu.ngfactory";
import * as i4 from "primeng/components/tabmenu/tabmenu";
import * as i5 from "../../../../../../node_modules/primeng/components/card/card.ngfactory";
import * as i6 from "primeng/components/card/card";
import * as i7 from "@angular/common";
import * as i8 from "@angular/router";
import * as i9 from "./authentication.component";
var styles_AuthenticationComponent = [];
var RenderType_AuthenticationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthenticationComponent, data: {} });
export { RenderType_AuthenticationComponent as RenderType_AuthenticationComponent };
function View_AuthenticationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "p-header", [], null, null, null, i1.View_Header_0, i1.RenderType_Header)), i0.ɵdid(1, 49152, [[1, 4]], 0, i2.Header, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 2, "p-tabMenu", [], null, null, null, i3.View_TabMenu_0, i3.RenderType_TabMenu)), i0.ɵdid(3, 1097728, null, 1, i4.TabMenu, [], { model: [0, "model"] }, null), i0.ɵqud(603979776, 3, { templates: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AuthenticationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "container login-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 8, "section", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "p-card", [], null, null, null, i5.View_Card_0, i5.RenderType_Card)), i0.ɵdid(3, 49152, null, 2, i6.Card, [i0.ElementRef], null, null), i0.ɵqud(603979776, 1, { headerFacet: 0 }), i0.ɵqud(335544320, 2, { footerFacet: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AuthenticationComponent_1)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 16777216, null, 1, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i8.RouterOutlet, [i8.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵeld(10, 0, null, null, 1, "aside", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/gif_kadr.gif"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isTabsVisible; _ck(_v, 7, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_AuthenticationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-authentication", [], null, null, null, View_AuthenticationComponent_0, RenderType_AuthenticationComponent)), i0.ɵdid(1, 114688, null, 0, i9.AuthenticationComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthenticationComponentNgFactory = i0.ɵccf("app-authentication", i9.AuthenticationComponent, View_AuthenticationComponent_Host_0, {}, {}, []);
export { AuthenticationComponentNgFactory as AuthenticationComponentNgFactory };
