/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { CollectionType } from '../models/collection-type';

/**
 * Collection Type Controller
 */
@Injectable({
  providedIn: 'root',
})
class CollectionTypeControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return OK
   */
  findAllCollectionTypeUsingGETResponse(): __Observable<__StrictHttpResponse<Array<CollectionType>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<CollectionType>>;
      })
    );
  }
  /**
   * @return OK
   */
  findAllCollectionTypeUsingGET(): __Observable<Array<CollectionType>> {
    return this.findAllCollectionTypeUsingGETResponse().pipe(
      __map(_r => _r.body as Array<CollectionType>)
    );
  }

  /**
   * @param collectionType collectionType
   * @return OK
   */
  addCollectionTypeUsingPOSTResponse(collectionType: CollectionType): __Observable<__StrictHttpResponse<CollectionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = collectionType;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionType>;
      })
    );
  }
  /**
   * @param collectionType collectionType
   * @return OK
   */
  addCollectionTypeUsingPOST(collectionType: CollectionType): __Observable<CollectionType> {
    return this.addCollectionTypeUsingPOSTResponse(collectionType).pipe(
      __map(_r => _r.body as CollectionType)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  findOneCollectionTypeUsingGETResponse(id: number): __Observable<__StrictHttpResponse<CollectionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/webitem/collection/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionType>;
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  findOneCollectionTypeUsingGET(id: number): __Observable<CollectionType> {
    return this.findOneCollectionTypeUsingGETResponse(id).pipe(
      __map(_r => _r.body as CollectionType)
    );
  }

  /**
   * @param params The `CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `collectionType`: collectionType
   *
   * @return OK
   */
  updateCollectionTypeUsingPOSTResponse(params: CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams): __Observable<__StrictHttpResponse<CollectionType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.collectionType;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/webitem/collection/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<CollectionType>;
      })
    );
  }
  /**
   * @param params The `CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `collectionType`: collectionType
   *
   * @return OK
   */
  updateCollectionTypeUsingPOST(params: CollectionTypeControllerService.UpdateCollectionTypeUsingPOSTParams): __Observable<CollectionType> {
    return this.updateCollectionTypeUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as CollectionType)
    );
  }

  /**
   * @param id id
   */
  updateCollectionTypeUsingDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/webitem/collection/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id id
   */
  updateCollectionTypeUsingDELETE(id: number): __Observable<null> {
    return this.updateCollectionTypeUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module CollectionTypeControllerService {

  /**
   * Parameters for updateCollectionTypeUsingPOST
   */
  export interface UpdateCollectionTypeUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * collectionType
     */
    collectionType: CollectionType;
  }
}

export { CollectionTypeControllerService }
