import * as i0 from "@angular/core";
/**
 * Global configuration for Api services
 */
export class ApiConfiguration {
    constructor() {
        this.rootUrl = '//origin.smarthtml.io';
    }
}
ApiConfiguration.ngInjectableDef = i0.defineInjectable({ factory: function ApiConfiguration_Factory() { return new ApiConfiguration(); }, token: ApiConfiguration, providedIn: "root" });
