import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SignupSuccesComponent } from './signup-succes/signup-succes.component';
import { AuthenticationComponent } from './authentication.component';
import { SendPasswordTokenComponent } from './send-password-token/send-password-token.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SendPasswordTokenSuccessComponent } from './send-password-token-success/send-password-token-success.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthenticationComponent,
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'signup',
        component: SignupComponent
      },
      {
        path: 'signup-success',
        component: SignupSuccesComponent
      },
      {
        path: 'send-password-token',
        component: SendPasswordTokenComponent
      },
      {
        path: 'reset-password/:token',
        component: ResetPasswordComponent
      },
      {
        path: 'send-password-token-success',
        component: SendPasswordTokenSuccessComponent
      },
      {
        path: 'reset-password-success',
        component: ResetPasswordSuccessComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule {}
