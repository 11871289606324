/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { Site } from '../models/site';

/**
 * Account Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class AccountRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AccountRestControllerService.FindAllUsingGET2Params` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `siteUid`:
   *
   * @return OK
   */
  findAllUsingGET2Response(params: AccountRestControllerService.FindAllUsingGET2Params): __Observable<__StrictHttpResponse<Array<Account>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.siteUid != null) __params = __params.set('siteUid', params.siteUid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin-api/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Account>>;
      })
    );
  }
  /**
   * @param params The `AccountRestControllerService.FindAllUsingGET2Params` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `siteUid`:
   *
   * @return OK
   */
  findAllUsingGET2(params: AccountRestControllerService.FindAllUsingGET2Params): __Observable<Array<Account>> {
    return this.findAllUsingGET2Response(params).pipe(
      __map(_r => _r.body as Array<Account>)
    );
  }

  /**
   * @param params The `AccountRestControllerService.CreateUsingPOSTParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `siteUid`:
   *
   * @return OK
   */
  createUsingPOSTResponse(params: AccountRestControllerService.CreateUsingPOSTParams): __Observable<__StrictHttpResponse<Account>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.siteUid != null) __params = __params.set('siteUid', params.siteUid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/admin-api/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Account>;
      })
    );
  }
  /**
   * @param params The `AccountRestControllerService.CreateUsingPOSTParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `siteUid`:
   *
   * @return OK
   */
  createUsingPOST(params: AccountRestControllerService.CreateUsingPOSTParams): __Observable<Account> {
    return this.createUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Account)
    );
  }

  /**
   * @return OK
   */
  findAllUsingGETResponse(): __Observable<__StrictHttpResponse<Array<Account>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin-api/account/all`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Account>>;
      })
    );
  }
  /**
   * @return OK
   */
  findAllUsingGET(): __Observable<Array<Account>> {
    return this.findAllUsingGETResponse().pipe(
      __map(_r => _r.body as Array<Account>)
    );
  }

  /**
   * @param accountUid accountUid
   * @return OK
   */
  findAllUsingGET1Response(accountUid: string): __Observable<__StrictHttpResponse<Array<Site>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin-api/account/${accountUid}/site`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Site>>;
      })
    );
  }
  /**
   * @param accountUid accountUid
   * @return OK
   */
  findAllUsingGET1(accountUid: string): __Observable<Array<Site>> {
    return this.findAllUsingGET1Response(accountUid).pipe(
      __map(_r => _r.body as Array<Site>)
    );
  }

  /**
   * @param params The `AccountRestControllerService.FindOneUsingGETParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  findOneUsingGETResponse(params: AccountRestControllerService.FindOneUsingGETParams): __Observable<__StrictHttpResponse<Account>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.siteUid != null) __params = __params.set('siteUid', params.siteUid.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/admin-api/account/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Account>;
      })
    );
  }
  /**
   * @param params The `AccountRestControllerService.FindOneUsingGETParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  findOneUsingGET(params: AccountRestControllerService.FindOneUsingGETParams): __Observable<Account> {
    return this.findOneUsingGETResponse(params).pipe(
      __map(_r => _r.body as Account)
    );
  }

  /**
   * @param params The `AccountRestControllerService.UpdateUsingPOSTParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  updateUsingPOSTResponse(params: AccountRestControllerService.UpdateUsingPOSTParams): __Observable<__StrictHttpResponse<Account>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.siteUid != null) __params = __params.set('siteUid', params.siteUid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/admin-api/account/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Account>;
      })
    );
  }
  /**
   * @param params The `AccountRestControllerService.UpdateUsingPOSTParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  updateUsingPOST(params: AccountRestControllerService.UpdateUsingPOSTParams): __Observable<Account> {
    return this.updateUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Account)
    );
  }

  /**
   * @param params The `AccountRestControllerService.DeleteAccountUsingDELETEParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  deleteAccountUsingDELETEResponse(params: AccountRestControllerService.DeleteAccountUsingDELETEParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;


    if (params.siteUid != null) __params = __params.set('siteUid', params.siteUid.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/admin-api/account/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `AccountRestControllerService.DeleteAccountUsingDELETEParams` containing the following parameters:
   *
   * - `vars`: vars
   *
   * - `id`: id
   *
   * - `siteUid`:
   *
   * @return OK
   */
  deleteAccountUsingDELETE(params: AccountRestControllerService.DeleteAccountUsingDELETEParams): __Observable<string> {
    return this.deleteAccountUsingDELETEResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module AccountRestControllerService {

  /**
   * Parameters for findAllUsingGET2
   */
  export interface FindAllUsingGET2Params {

    /**
     * vars
     */
    vars: string;
    siteUid?: string;
  }

  /**
   * Parameters for createUsingPOST
   */
  export interface CreateUsingPOSTParams {

    /**
     * vars
     */
    vars: string;
    siteUid?: string;
  }

  /**
   * Parameters for findOneUsingGET
   */
  export interface FindOneUsingGETParams {

    /**
     * vars
     */
    vars: string;

    /**
     * id
     */
    id: number;
    siteUid?: string;
  }

  /**
   * Parameters for updateUsingPOST
   */
  export interface UpdateUsingPOSTParams {

    /**
     * vars
     */
    vars: string;

    /**
     * id
     */
    id: number;
    siteUid?: string;
  }

  /**
   * Parameters for deleteAccountUsingDELETE
   */
  export interface DeleteAccountUsingDELETEParams {

    /**
     * vars
     */
    vars: string;

    /**
     * id
     */
    id: number;
    siteUid?: string;
  }
}

export { AccountRestControllerService }
