/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Site } from '../models/site';
import { CreateSiteModel } from '../models/create-site-model';

/**
 * Admin Site Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class AdminSiteRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `AdminSiteRestControllerService.CreateSiteUsingPOSTParams` containing the following parameters:
   *
   * - `site`: site
   *
   * - `accountUid`: accountUid
   *
   * @return OK
   */
  createSiteUsingPOSTResponse(params: AdminSiteRestControllerService.CreateSiteUsingPOSTParams): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.site;
    if (params.accountUid != null) __params = __params.set('accountUid', params.accountUid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/admin-api/site`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * @param params The `AdminSiteRestControllerService.CreateSiteUsingPOSTParams` containing the following parameters:
   *
   * - `site`: site
   *
   * - `accountUid`: accountUid
   *
   * @return OK
   */
  createSiteUsingPOST(params: AdminSiteRestControllerService.CreateSiteUsingPOSTParams): __Observable<Site> {
    return this.createSiteUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * @param createSiteModel createSiteModel
   * @return OK
   */
  addSiteUsingPOSTResponse(createSiteModel: CreateSiteModel): __Observable<__StrictHttpResponse<Site>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = createSiteModel;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/admin-api/site/add`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Site>;
      })
    );
  }
  /**
   * @param createSiteModel createSiteModel
   * @return OK
   */
  addSiteUsingPOST(createSiteModel: CreateSiteModel): __Observable<Site> {
    return this.addSiteUsingPOSTResponse(createSiteModel).pipe(
      __map(_r => _r.body as Site)
    );
  }

  /**
   * @param siteUid siteUid
   */
  deleteSiteUsingDELETEResponse(siteUid: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/admin-api/site/${siteUid}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param siteUid siteUid
   */
  deleteSiteUsingDELETE(siteUid: string): __Observable<null> {
    return this.deleteSiteUsingDELETEResponse(siteUid).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AdminSiteRestControllerService {

  /**
   * Parameters for createSiteUsingPOST
   */
  export interface CreateSiteUsingPOSTParams {

    /**
     * site
     */
    site: Site;

    /**
     * accountUid
     */
    accountUid: string;
  }
}

export { AdminSiteRestControllerService }
