import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Provider Controller
 */
class ProviderControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `ProviderControllerService.ItemsDataUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    itemsDataUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/headless/items/data/${params.revisionTag}/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ProviderControllerService.ItemsDataUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    itemsDataUsingGET(params) {
        return this.itemsDataUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ProviderControllerService.ItemsDownloadUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    itemsDownloadUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/headless/items/resource/${params.revisionTag}/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ProviderControllerService.ItemsDownloadUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * @return OK
     */
    itemsDownloadUsingGET(params) {
        return this.itemsDownloadUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ProviderControllerService.ItemsSearchUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `q`: q
     *
     * @return OK
     */
    itemsSearchUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.q != null)
            __params = __params.set('q', params.q.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/headless/items/search`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ProviderControllerService.ItemsSearchUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `q`: q
     *
     * @return OK
     */
    itemsSearchUsingGET(params) {
        return this.itemsSearchUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ProviderControllerService.RenderitemsDataUsingGETParams` containing the following parameters:
     *
     * - `contextPath`: contextPath
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    renderitemsDataUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.contextPath != null)
            __params = __params.set('contextPath', params.contextPath.toString());
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/headless/renderitems/data`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ProviderControllerService.RenderitemsDataUsingGETParams` containing the following parameters:
     *
     * - `contextPath`: contextPath
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    renderitemsDataUsingGET(params) {
        return this.renderitemsDataUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ProviderControllerService.RenderitemsSearchUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `q`: q
     *
     * @return OK
     */
    renderitemsSearchUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.q != null)
            __params = __params.set('q', params.q.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/headless/renderitems/search`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ProviderControllerService.RenderitemsSearchUsingGETParams` containing the following parameters:
     *
     * - `revisionTag`: revisionTag
     *
     * - `q`: q
     *
     * @return OK
     */
    renderitemsSearchUsingGET(params) {
        return this.renderitemsSearchUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
}
ProviderControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ProviderControllerService_Factory() { return new ProviderControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ProviderControllerService, providedIn: "root" });
export { ProviderControllerService };
