import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupModel } from '@api/models/signup-model';
import { ResetPasswordModel } from '@api/models/reset-password-model';
import { ResetPasswordAction, SendPasswordLinkAction } from '../state/auth.actions';
import { Store } from '@ngxs/store';

export interface SetPasswordForm extends ResetPasswordModel {
  password2: string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isError = false;
  token = '';
  model: ResetPasswordModel = {};

  constructor(private readonly route: ActivatedRoute, private readonly router: Router, private store: Store) {}

  ngOnInit() {
    //this.token = this.route.snapshot.paramMap.get("token");

    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      this.model.token = this.token;
    });
  }

  async submitForm() {
    this.isError = false;

    try {
      await this.store.dispatch(new ResetPasswordAction(this.model)).toPromise();
    } catch (error) {
      this.isError = true;
    }
  }

  backToLogin() {
    this.router.navigate(['auth/login']);
  }
}
