import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'sh-button-with-label',
  template: `
    <div class="ui-button-with-label {{additionalClass}}">
      <p-button [icon]="icon" [styleClass]="styleClass" (onClick)="click($event)"></p-button>
      <span class="ui-button-with-label__label">{{label}}</span>
    </div>
  `,
  styles: [
    `
    
    :host {
      cursor: pointer;
    }
    
  `
  ]
})
export class ButtonWithLabelComponent {
  @Input() icon: string;
  @Input() styleClass: string;
  @Input() label: string;
  @Input() additionalClass: string;
  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  click(evt) {
    this.onClick.next(evt);
  }
}
