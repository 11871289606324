import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/auth.service';
import { Store } from '@ngxs/store';
import { SendPasswordLinkAction } from '../state/auth.actions';
export class SendPasswordTokenComponent {
    constructor(router, auth, store) {
        this.router = router;
        this.auth = auth;
        this.store = store;
        this.isError = false;
        this.model = {
            email: ''
        };
    }
    ngOnInit() { }
    submitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.isError = false;
            try {
                yield this.store.dispatch(new SendPasswordLinkAction(this.model)).toPromise();
            }
            catch (error) {
                this.isError = true;
            }
        });
    }
    backToLogin() {
        this.router.navigate(['auth/login']);
    }
}
