/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { DeployRequest } from '../models/deploy-request';
import { DeploySettings } from '../models/deploy-settings';
import { ResponseEntity } from '../models/response-entity';

/**
 * Deploy Rest Controller
 */
@Injectable({
  providedIn: 'root',
})
class DeployRestControllerService extends __BaseService {
  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `DeployRestControllerService.InitDeployUsingPOSTParams` containing the following parameters:
   *
   * - `deploySettings`: deploySettings
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  initDeployUsingPOSTResponse(params: DeployRestControllerService.InitDeployUsingPOSTParams): __Observable<__StrictHttpResponse<DeployRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.deploySettings;
    if (params.revisionTag != null) __params = __params.set('revisionTag', params.revisionTag.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/deploy/init`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeployRequest>;
      })
    );
  }
  /**
   * @param params The `DeployRestControllerService.InitDeployUsingPOSTParams` containing the following parameters:
   *
   * - `deploySettings`: deploySettings
   *
   * - `revisionTag`: revisionTag
   *
   * @return OK
   */
  initDeployUsingPOST(params: DeployRestControllerService.InitDeployUsingPOSTParams): __Observable<DeployRequest> {
    return this.initDeployUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as DeployRequest)
    );
  }

  /**
   * @return OK
   */
  listDeployRequestsUsingGET2Response(): __Observable<__StrictHttpResponse<Array<DeployRequest>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DeployRequest>>;
      })
    );
  }
  /**
   * @return OK
   */
  listDeployRequestsUsingGET2(): __Observable<Array<DeployRequest>> {
    return this.listDeployRequestsUsingGET2Response().pipe(
      __map(_r => _r.body as Array<DeployRequest>)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  listDeployRequestsUsingGET1Response(id: number): __Observable<__StrictHttpResponse<DeployRequest>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/list/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeployRequest>;
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  listDeployRequestsUsingGET1(id: number): __Observable<DeployRequest> {
    return this.listDeployRequestsUsingGET1Response(id).pipe(
      __map(_r => _r.body as DeployRequest)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  listDeployRequestsUsingGETResponse(id: number): __Observable<__StrictHttpResponse<ResponseEntity>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/list/${id}/zip`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseEntity>;
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  listDeployRequestsUsingGET(id: number): __Observable<ResponseEntity> {
    return this.listDeployRequestsUsingGETResponse(id).pipe(
      __map(_r => _r.body as ResponseEntity)
    );
  }

  /**
   * @return OK
   */
  listDeploySettingsUsingGETResponse(): __Observable<__StrictHttpResponse<Array<DeploySettings>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DeploySettings>>;
      })
    );
  }
  /**
   * @return OK
   */
  listDeploySettingsUsingGET(): __Observable<Array<DeploySettings>> {
    return this.listDeploySettingsUsingGETResponse().pipe(
      __map(_r => _r.body as Array<DeploySettings>)
    );
  }

  /**
   * @param deploySettings deploySettings
   * @return OK
   */
  createDeploySettingsUsingPOSTResponse(deploySettings: DeploySettings): __Observable<__StrictHttpResponse<DeploySettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = deploySettings;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/deploy/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeploySettings>;
      })
    );
  }
  /**
   * @param deploySettings deploySettings
   * @return OK
   */
  createDeploySettingsUsingPOST(deploySettings: DeploySettings): __Observable<DeploySettings> {
    return this.createDeploySettingsUsingPOSTResponse(deploySettings).pipe(
      __map(_r => _r.body as DeploySettings)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  getDeploySettingsByIdUsingGETResponse(id: number): __Observable<__StrictHttpResponse<DeploySettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/settings/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeploySettings>;
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  getDeploySettingsByIdUsingGET(id: number): __Observable<DeploySettings> {
    return this.getDeploySettingsByIdUsingGETResponse(id).pipe(
      __map(_r => _r.body as DeploySettings)
    );
  }

  /**
   * @param params The `DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `deploySettings`: deploySettings
   *
   * @return OK
   */
  updateDeploySettingsByIdUsingPOSTResponse(params: DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams): __Observable<__StrictHttpResponse<DeploySettings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.deploySettings;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/deploy/settings/${params.id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DeploySettings>;
      })
    );
  }
  /**
   * @param params The `DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams` containing the following parameters:
   *
   * - `id`: id
   *
   * - `deploySettings`: deploySettings
   *
   * @return OK
   */
  updateDeploySettingsByIdUsingPOST(params: DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams): __Observable<DeploySettings> {
    return this.updateDeploySettingsByIdUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as DeploySettings)
    );
  }

  /**
   * @param id id
   */
  deleteDeploySettingsByIdUsingDELETEResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/deploy/settings/${id}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id id
   */
  deleteDeploySettingsByIdUsingDELETE(id: number): __Observable<null> {
    return this.deleteDeploySettingsByIdUsingDELETEResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id id
   * @return OK
   */
  deployRequestListUsingGETResponse(id: number): __Observable<__StrictHttpResponse<Array<DeployRequest>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/deploy/settings/${id}/requests`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<DeployRequest>>;
      })
    );
  }
  /**
   * @param id id
   * @return OK
   */
  deployRequestListUsingGET(id: number): __Observable<Array<DeployRequest>> {
    return this.deployRequestListUsingGETResponse(id).pipe(
      __map(_r => _r.body as Array<DeployRequest>)
    );
  }
}

module DeployRestControllerService {

  /**
   * Parameters for initDeployUsingPOST
   */
  export interface InitDeployUsingPOSTParams {

    /**
     * deploySettings
     */
    deploySettings: DeploySettings;

    /**
     * revisionTag
     */
    revisionTag?: string;
  }

  /**
   * Parameters for updateDeploySettingsByIdUsingPOST
   */
  export interface UpdateDeploySettingsByIdUsingPOSTParams {

    /**
     * id
     */
    id: number;

    /**
     * deploySettings
     */
    deploySettings: DeploySettings;
  }
}

export { DeployRestControllerService }
