import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Deploy Rest Controller
 */
class DeployRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `DeployRestControllerService.InitDeployUsingPOSTParams` containing the following parameters:
     *
     * - `deploySettings`: deploySettings
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    initDeployUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.deploySettings;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/deploy/init`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `DeployRestControllerService.InitDeployUsingPOSTParams` containing the following parameters:
     *
     * - `deploySettings`: deploySettings
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    initDeployUsingPOST(params) {
        return this.initDeployUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    listDeployRequestsUsingGET2Response() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/list`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    listDeployRequestsUsingGET2() {
        return this.listDeployRequestsUsingGET2Response().pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    listDeployRequestsUsingGET1Response(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/list/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    listDeployRequestsUsingGET1(id) {
        return this.listDeployRequestsUsingGET1Response(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    listDeployRequestsUsingGETResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/list/${id}/zip`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    listDeployRequestsUsingGET(id) {
        return this.listDeployRequestsUsingGETResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    listDeploySettingsUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/settings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    listDeploySettingsUsingGET() {
        return this.listDeploySettingsUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @param deploySettings deploySettings
     * @return OK
     */
    createDeploySettingsUsingPOSTResponse(deploySettings) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = deploySettings;
        let req = new HttpRequest('POST', this.rootUrl + `/api/deploy/settings`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param deploySettings deploySettings
     * @return OK
     */
    createDeploySettingsUsingPOST(deploySettings) {
        return this.createDeploySettingsUsingPOSTResponse(deploySettings).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    getDeploySettingsByIdUsingGETResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/settings/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    getDeploySettingsByIdUsingGET(id) {
        return this.getDeploySettingsByIdUsingGETResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `deploySettings`: deploySettings
     *
     * @return OK
     */
    updateDeploySettingsByIdUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.deploySettings;
        let req = new HttpRequest('POST', this.rootUrl + `/api/deploy/settings/${params.id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `DeployRestControllerService.UpdateDeploySettingsByIdUsingPOSTParams` containing the following parameters:
     *
     * - `id`: id
     *
     * - `deploySettings`: deploySettings
     *
     * @return OK
     */
    updateDeploySettingsByIdUsingPOST(params) {
        return this.updateDeploySettingsByIdUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     */
    deleteDeploySettingsByIdUsingDELETEResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/deploy/settings/${id}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     */
    deleteDeploySettingsByIdUsingDELETE(id) {
        return this.deleteDeploySettingsByIdUsingDELETEResponse(id).pipe(__map(_r => _r.body));
    }
    /**
     * @param id id
     * @return OK
     */
    deployRequestListUsingGETResponse(id) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/api/deploy/settings/${id}/requests`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param id id
     * @return OK
     */
    deployRequestListUsingGET(id) {
        return this.deployRequestListUsingGETResponse(id).pipe(__map(_r => _r.body));
    }
}
DeployRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function DeployRestControllerService_Factory() { return new DeployRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: DeployRestControllerService, providedIn: "root" });
export { DeployRestControllerService };
