import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Item Rest Controller
 */
class ItemRestControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `ItemRestControllerService.ListItemsUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    listItemsUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/items/data/${params.collectionTypeName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.ListItemsUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    listItemsUsingGET(params) {
        return this.listItemsUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.SaveDataUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveDataUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/data/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.SaveDataUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveDataUsingPOST(params) {
        return this.saveDataUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.CreateFolderUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    createFolderUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/folder/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.CreateFolderUsingPOSTParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    createFolderUsingPOST(params) {
        return this.createFolderUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.DeleteFolderUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `keepRoot`: keepRoot
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    deleteFolderUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.keepRoot != null)
            __params = __params.set('keepRoot', params.keepRoot.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/items/folder/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.DeleteFolderUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `keepRoot`: keepRoot
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    deleteFolderUsingDELETE(params) {
        return this.deleteFolderUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.GetFormModelUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getFormModelUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/items/formmodel/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.GetFormModelUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getFormModelUsingGET(params) {
        return this.getFormModelUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.SaveFormModelUsingPOSTParams` containing the following parameters:
     *
     * - `itemFormSection`: itemFormSection
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveFormModelUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __body = params.itemFormSection;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/formmodel/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.SaveFormModelUsingPOSTParams` containing the following parameters:
     *
     * - `itemFormSection`: itemFormSection
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveFormModelUsingPOST(params) {
        return this.saveFormModelUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    parseMetadataStringUsingPOSTResponse(revisionTag) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (revisionTag != null)
            __params = __params.set('revisionTag', revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/metadata`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param revisionTag revisionTag
     * @return OK
     */
    parseMetadataStringUsingPOST(revisionTag) {
        return this.parseMetadataStringUsingPOSTResponse(revisionTag).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.ParseMetadataUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    parseMetadataUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/items/metadata/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.ParseMetadataUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    parseMetadataUsingGET(params) {
        return this.parseMetadataUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.GetResourceUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getResourceUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.GetResourceUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    getResourceUsingGET(params) {
        return this.getResourceUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.SaveResourceUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveResourceUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __headers.append('Content-Type', 'multipart/form-data');
        let __formData = new FormData();
        __body = __formData;
        if (params.file !== null && typeof params.file !== "undefined") {
            __formData.append('file', params.file);
        }
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.SaveResourceUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    saveResourceUsingPOST(params) {
        return this.saveResourceUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.DeleteResourceUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    deleteResourceUsingDELETEResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.filePath != null)
            __params = __params.set('filePath', params.filePath.toString());
        let req = new HttpRequest('DELETE', this.rootUrl + `/api/items/resource/${params.collectionTypeName}/**`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.DeleteResourceUsingDELETEParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `filePath`: filePath
     *
     * @return OK
     */
    deleteResourceUsingDELETE(params) {
        return this.deleteResourceUsingDELETEResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.GetAssetsTreeUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `parentFolderId`: parentFolderId
     *
     * - `depth`: depth
     *
     * @return OK
     */
    getAssetsTreeUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        if (params.parentFolderId != null)
            __params = __params.set('parentFolderId', params.parentFolderId.toString());
        if (params.depth != null)
            __params = __params.set('depth', params.depth.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/api/items/tree/${params.collectionTypeName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.GetAssetsTreeUsingGETParams` containing the following parameters:
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * - `parentFolderId`: parentFolderId
     *
     * - `depth`: depth
     *
     * @return OK
     */
    getAssetsTreeUsingGET(params) {
        return this.getAssetsTreeUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @param params The `ItemRestControllerService.UploadThemeUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    uploadThemeUsingPOSTResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        __headers.append('Content-Type', 'multipart/form-data');
        let __formData = new FormData();
        __body = __formData;
        if (params.file !== null && typeof params.file !== "undefined") {
            __formData.append('file', params.file);
        }
        if (params.revisionTag != null)
            __params = __params.set('revisionTag', params.revisionTag.toString());
        let req = new HttpRequest('POST', this.rootUrl + `/api/items/zip/${params.collectionTypeName}`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'json'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `ItemRestControllerService.UploadThemeUsingPOSTParams` containing the following parameters:
     *
     * - `file`: file
     *
     * - `collectionTypeName`: collectionTypeName
     *
     * - `revisionTag`: revisionTag
     *
     * @return OK
     */
    uploadThemeUsingPOST(params) {
        return this.uploadThemeUsingPOSTResponse(params).pipe(__map(_r => _r.body));
    }
}
ItemRestControllerService.ngInjectableDef = i0.defineInjectable({ factory: function ItemRestControllerService_Factory() { return new ItemRestControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: ItemRestControllerService, providedIn: "root" });
export { ItemRestControllerService };
