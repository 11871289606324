import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appSamePassword]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SamePasswordDirective, multi: true }]
})
export class SamePasswordDirective implements Validator {
  validate(control: AbstractControl) {
    const password = control.parent.controls['password'].value;
    const password2 = control.value;

    if (password !== password2) {
      return {
        notFit: true
      };
    } else {
      return null;
    }
  }
}
