/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./app-version.component";
var styles_AppVersionComponent = [];
var RenderType_AppVersionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppVersionComponent, data: {} });
export { RenderType_AppVersionComponent as RenderType_AppVersionComponent };
export function View_AppVersionComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "app-version"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 1, 0, currVal_0); }); }
export function View_AppVersionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "sh-app-version", [], null, null, null, View_AppVersionComponent_0, RenderType_AppVersionComponent)), i0.ɵdid(1, 114688, null, 0, i1.AppVersionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppVersionComponentNgFactory = i0.ɵccf("sh-app-version", i1.AppVersionComponent, View_AppVersionComponent_Host_0, { versionData: "versionData" }, {}, []);
export { AppVersionComponentNgFactory as AppVersionComponentNgFactory };
