import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { map as __map, filter as __filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../api-configuration";
import * as i2 from "@angular/common/http";
/**
 * Web Controller
 */
class WebControllerService extends __BaseService {
    constructor(config, http) {
        super(config, http);
    }
    /**
     * @param params The `WebControllerService.LogsUsingGETParams` containing the following parameters:
     *
     * - `uid`: uid
     *
     * - `size`: size
     *
     * - `page`: page
     *
     * @return OK
     */
    logsUsingGETResponse(params) {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        if (params.uid != null)
            __params = __params.set('uid', params.uid.toString());
        if (params.size != null)
            __params = __params.set('size', params.size.toString());
        if (params.page != null)
            __params = __params.set('page', params.page.toString());
        let req = new HttpRequest('GET', this.rootUrl + `/logs`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @param params The `WebControllerService.LogsUsingGETParams` containing the following parameters:
     *
     * - `uid`: uid
     *
     * - `size`: size
     *
     * - `page`: page
     *
     * @return OK
     */
    logsUsingGET(params) {
        return this.logsUsingGETResponse(params).pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    signinUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/signin.html`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    signinUsingGET() {
        return this.signinUsingGETResponse().pipe(__map(_r => _r.body));
    }
    /**
     * @return OK
     */
    loginUsingGETResponse() {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body = null;
        let req = new HttpRequest('GET', this.rootUrl + `/weblogin`, __body, {
            headers: __headers,
            params: __params,
            responseType: 'text'
        });
        return this.http.request(req).pipe(__filter(_r => _r instanceof HttpResponse), __map((_r) => {
            return _r;
        }));
    }
    /**
     * @return OK
     */
    loginUsingGET() {
        return this.loginUsingGETResponse().pipe(__map(_r => _r.body));
    }
}
WebControllerService.ngInjectableDef = i0.defineInjectable({ factory: function WebControllerService_Factory() { return new WebControllerService(i0.inject(i1.ApiConfiguration), i0.inject(i2.HttpClient)); }, token: WebControllerService, providedIn: "root" });
export { WebControllerService };
